import React from 'react';
import './DefaultPagination.sass'; 
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  totalRecords: number; 
}

const DefaultPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, totalRecords }) => {

  totalPages = !isNaN(totalPages) && totalPages > 0 ? totalPages : 1;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pages: React.ReactNode[] = [];

    // Always show the first page
    pages.push(
      <span
        key={1}
        className={`pagination-number first-page ${currentPage === 1 ? 'active' : ''}`}
        onClick={() => handlePageChange(1)}
      >
        1
      </span>
    );

    let start = Math.max(currentPage - 1, 2);
    let end = Math.min(currentPage + 1, totalPages - 1);

    if (currentPage <= 2) {
      start = 2;
      end = 4;
    }

    if (currentPage >= totalPages - 1) {
      start = totalPages - 3;
      end = totalPages - 1;
    }

    if (totalPages <= 5) {
      start = 2;
      end = totalPages - 1;
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <span
          key={i}
          className={`pagination-number middle-page ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <span
          key={totalPages}
          className={`pagination-number last-page ${currentPage === totalPages ? 'active' : ''}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </span>
      );
    }

    return pages;
  };

  return (
    <div className='pagination-container'>
      <div className="pagination-info-left">
        Página {currentPage} de {totalPages}
      </div>
      <div className="pagination">
        <span
          className={`pagination-arrow ${isFirstPage ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <MdArrowBackIos />
        </span>
        {renderPageNumbers()}
        <span
          className={`pagination-arrow ${isLastPage ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <MdArrowForwardIos />
        </span>
      </div>
      <div className="pagination-info-right">
        {totalRecords} {totalRecords === 1 ? 'registro' : `registros`}
      </div>
    </div>
  );
};

export default DefaultPagination;






