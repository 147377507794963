import React, { useEffect, useState } from 'react';
import CardEventActive from '../../dashboard-custom-components/components/Card/CardEventActive/CardEventActive';
import { format } from 'date-fns';

interface EventData {
  teamVisiting: string;
  teamHome: string;
  eventTime: string;
  eventDate: string;
  eventDay: string;
  eventDescription: string;
  isActive: boolean;
  eventName: string;
  eventType: string;
  externalId: string | null;
  id: string;
  locationId: string;
}

const formatEventData = (event: any): EventData => {
  const date = new Date(event.startDateTime);
  const formattedDate = format(date, 'dd/MM/yyyy');
  const formattedTime = format(date, 'HH:mm');
  
  let teamHome = '';
  let teamVisiting = '';
  let eventName = event.title;

  if (event.eventType === 'game') {
    const match = event.title.match(/(.+?)\s+x\s+(.+)/i);
    if (match) {
      teamHome = match[1].trim();
      teamVisiting = match[2].trim();
      eventName = ''; 
    } else {
      console.error('Regex did not match:', event.title);
    }
  }
  
  return {
    eventDate: formattedDate,
    eventName,
    eventDay: date.toLocaleDateString('pt-BR', { weekday: 'long' }),
    eventTime: formattedTime,
    eventDescription: event?.eventDescription,
    isActive: event?.isActive,
    eventType: event?.eventType,
    teamVisiting,
    teamHome,
    externalId: event.eventCode,
    id: event.eventCode,
    locationId: '' 
  };
};

const EventActiveCard: React.FC<{ event: EventData | null }> = ({ event }) => {
  const [eventData, setEventData] = useState<EventData | null>(null);
  
  useEffect(() => {
    if (event) {
      const formattedData = formatEventData(event);
      setEventData(formattedData);
    }
  }, [event]);

  return (
    <div>
      {eventData && (
        <CardEventActive
          eventDate={eventData.eventDate}
          eventName={eventData.eventName}
          eventDay={eventData.eventDay}
          eventDescription={eventData.eventDescription}
          isActive={eventData.isActive}
          eventTime={eventData.eventTime}
          eventType={eventData.eventType}
          teamVisiting={eventData.teamVisiting}
          teamHome={eventData.teamHome}
        />
      )}
    </div>
  );
};

export default EventActiveCard;
