import React, { useState, useEffect } from 'react';
import CardRecentEventAccess, { User } from '../../dashboard-custom-components/components/Card/CardRecentEventAccess/CardRecentEventAccess';
import { BASE } from '../../basedata/constants';
import Cookies from 'js-cookie';
import { format } from 'date-fns';

const LastAccess: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [nextEvent, setNextEvent] = useState<any>(null);

  useEffect(() => {
    fetchNextEvent();
  }, []);

  const fetchNextEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_next}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do próximo evento');
        }
        return response.json();
      })
      .then((data) => {
        setNextEvent(data);
        fetchData(data.event.eventCode);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do próximo evento:', error);
      });
  };

  const fetchData = (eventCode: string) => {
    const page = 1;
    const pageSize = 4;
    const orderByColumn = "createdAt";
    const orderByDirection = "DESC";
    const profile = Cookies.get('profile');
    const supplierId = Cookies.get('companyId');

    let requestBody: any = {
      eventId: eventCode,
      orderByColumn: orderByColumn,
      orderByDirection: orderByDirection,
      pageSize: pageSize,
      page: page
    };

    if (profile !== 'administrador' && supplierId) {
      requestBody = {
        ...requestBody,
        supplierId: supplierId,
      };
    }

    fetch(`${BASE.EVENT.url}${BASE.EVENT.last_accesses}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao buscar dados');
        }
        return response.json();
      })
      .then((data) => {
        const mappedUsers = mapDataToUsers(data.data);
        setUsers(mappedUsers);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados:', error);
      });
  };

  const mapDataToUsers = (data: any[]): User[] => {
    return data.map((item) => ({
      user_name: `${item.firstName} ${item.lastName}`,
      user_document_type: item.documentType || 'Documento',
      user_document: formatDocument(item.document, item.documentType),
      user_data_entry: format(new Date(item.createdAt), 'dd/MM/yyyy'),
      user_hour_entry: item.createdAt.substring(11, 16),
      supplier_name: item.supplierName,
      supplier_number: `Camarote ${item.supplierNumber || ''}`,
      isVip: item.isVip,
    }));
  };

  const formatTime = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDocument = (document: string | undefined, documentType: string | undefined): string => {
    if (documentType === 'CPF' && document) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return document || '';
  };

  return (
    <div>
      <CardRecentEventAccess users={users} to="/report/LastEntrances" />
    </div>
  );
};

export default LastAccess;


