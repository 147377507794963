import React from "react";
import "./DefaultButton.sass";

interface DefaultButtonProps {
  handleOnClick: () => void;
  label: string;
}

const DefaultButton: React.FC<DefaultButtonProps> = ({
  handleOnClick,
  label,
}) => {
  return (
    <button onClick={handleOnClick} className="button-wrapper">
      {label}
    </button>
  );
};

export default DefaultButton;
