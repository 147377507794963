import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'
import { BASE } from '../../basedata/constants'

import { Row, Col, Card, Tab, Tabs, Table, Form, Button } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal';

import { format } from 'date-fns';

import { CONFIG } from '../../config/configVariables'

import './UsersCallup.sass'

import Cookies from 'js-cookie';

import { Link, useParams } from 'react-router-dom'

import { initFlowbite } from 'flowbite'

// import * as XLSX from 'xlsx';
import { utils, read, writeFile } from 'xlsx';
import Dropzone from 'react-dropzone';

import { PiEye, PiLink, PiMegaphoneLight, PiSmiley, PiSmileyMeh, PiSmileyWink, PiUserCirclePlus } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { PiUserCircleLight } from "react-icons/pi";

import faceOK from '../../images/icon-smile.svg'
import faceNOK from '../../images/icon-smile-dotted.svg'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MdOutlineFileDownload } from 'react-icons/md';
import CardEventInfo from '../../dashboard-custom-components/components/Card/CardEventInfo/CardEventInfo';
import DefaultPagination from '../../dashboard-custom-components/components/Pagination/DefaultPagination';

import '../../dashboard-custom-components/components/Table/TableEvents/TableEventsList.sass';
import ModalShowWaitingList from '../components/modalShowWaitingList';
import DefaultButton from '../../dashboard-custom-components/components/Button/DefaultButton/DefaultButton';
import ModalSelectEvent from '../components/modalSelectEvent';
import HeaderLine from '../../shared/header';

const MySwal = withReactContent(Swal)

export default function UsersCallup() {

  const auth = sessionStorage.getItem('updatx001')

  const cabinId = Cookies.get('cabinId') || ''
  const suppliersList:any = JSON.parse(Cookies.get('suppliersList') || '')
  const supplierId = suppliersList.find((item) => item.cabinId == cabinId)?.id;
  const supplierName = suppliersList.find((item) => item.cabinId == cabinId)?.name;

  const parseListJsonCookie = (cookie: any) => {
    try {
      return JSON.parse(Cookies.get(cookie))
    } catch (e) {
      return []
    }
  }
  
  // const supplierId = parseListJsonCookie('suppliersList')[0].id

  const createGuestListExcel = () => {
    const data = [
      ["CPF", "Nome", "E-mail", "Celular"],
      ["999999999", "John Doe", "john.doe@email.com", "11999999999"],
      ["999999998", "Due Jhonson", "due.jhonson@email.com", "11999999999"]
    ];
  
    const ws = utils.aoa_to_sheet(data);
  
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Lista de Convidados");
  
    writeFile(wb, "exemplo_lista_de_convidados.xlsx");
  };

  const formatEventData = (event: any)  => {
    const date = new Date(event.startDateTime);
    const formattedDate = format(date, 'dd/MM/yyyy');
    const formattedTime = format(date, 'HH:mm');
    
    let teamHome = '';
    let teamVisiting = '';
    let eventName = event.title;
  
    if (event.eventType === 'game') {
      const match = event.title.match(/(.+?)\s+x\s+(.+)/i);
      if (match) {
        teamHome = match[1].trim();
        teamVisiting = match[2].trim();
        eventName = ''; 
      } else {
        console.error('Regex did not match:', event.title);
      }
    }

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
    
    const eventDay = capitalizeFirstLetter(date.toLocaleDateString('pt-BR', { weekday: 'long' }));
    
    return {
      eventDate: formattedDate,
      eventName,
      eventDay: eventDay,
      eventTime: formattedTime,
      eventDescription: event?.eventDescription,
      isActive: event?.isActive,
      eventType: event?.eventType,
      teamVisiting,
      teamHome,
      externalId: event.eventCode,
      id: event.eventCode,
      locationId: '' 
    };
  };
  
    const buttonData = useMemo(() => ({
      handleOnClick: () => handleShowModalEventList(),
      label: 'Ver outros eventos'
    }), []);


    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () =>  setShowModal(true);
    const handleHideModal = () => {
        setShowModal(false)
        setSingleUserDocument('');
        getWaitingList()
    };

    
    const [showModalEventList, setShowModalEventList] = useState(false);
    const handleShowModalEventList = () =>  setShowModalEventList(true);
    const handleHideModalEventList = () => {
        setResponseCounter({
            error: 0,
            success: 0,
            return: false
        })
        setTempUserCheck(null)
        setShowModalEventList(false)
    };
    const [eventId, setSelectedEventId] = useState<string | null>('next'); 
    const [eventName, setSelectedEventName] = useState<string>(''); 
    const [supplierData, setSupplierData] = useState<any>(null);
    const [waitingListCount, setWaitingListCount] = useState<any>(0);
    const [limitValidation, setLimitValidation] = useState<any>(false);
    
    const [loading, setLoading] = useState(false)

    // User list uploader
    const [tempUsersToLoad, setTempUsersToLoad] = useState<any>(null);
    const [tempUserCheck, setTempUserCheck] = useState<any>(null);
    const [usersListColumns, setUsersListColumns] = useState<any>([]);



    //User validation response
    const [singleUserDocument, setSingleUserDocument] = useState<any>();
    const [responseCounter, setResponseCounter] = useState({
        error: 0,
        success: 0,
        return: false
    });
    const [errorList, setErrorList] = useState<any>({
        simpleList: [],
        fullList: []
    });

    //User list
    const [userCommonList, setUserCommonList] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [usersTotal, setUsersTotal] = useState({
      total: 0,
      limit: 10000
    });

    //List handlers
    const [userIdHandlerList, setUserIdHandlerList] = useState<any>([]);
    const [searchBar, setSearchBar] = useState('null')
    const [searchTimeout, setSearchTimeout] = useState<any>(null)
    const [searchData, setSearchData] = useState<any>({});

    const getEventUsers = (searchTerm: any = null) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                page,
                pageSize,
                search: searchTerm
            })
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.users_provider}/${eventId}/${supplierId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserCommonList(data.result);
                setTotalPages(Math.ceil(data.total / pageSize));
                setUsersTotal({...usersTotal, total: data.total || 0});
            })
            .catch( error => {
                console.log(error)
                setLoading(false)
            });
    }

    const handleFileUpload = (acceptedFiles: any) => {


        setTempUsersToLoad(null);
        setTempUserCheck(null)

        setResponseCounter({
            error: 0,
            success: 0,
            return: false
        })
        setErrorList({
            simpleList: [],
            fullList: []
        })


        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });

            // Assuming the first sheet is the one you want to parse
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON
            const jsonData = utils.sheet_to_json(sheet);

            // Log rows by columns
            // jsonData.forEach((row) => {
            //   Object.keys(row).forEach((column) => {
            //     console.log(`Column: ${column}, Value: ${row[column]}`);
            //   });
            // });


            setShow(false)
            document.getElementById('orla')?.click()
            setTempUsersToLoad(jsonData);
            setTempUserCheck(jsonData[0])
        };

        fileReader.readAsArrayBuffer(acceptedFiles[0]);
    };

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).catch((err) => {
            console.error('Falha ao copiar o link', err);
        });
    }

    const [onboardingLinkText, setOnboardingLinkText] = useState({});

    const getOnboardingLink = (token, rowIndex) => {
        navigator.clipboard.writeText(`${BASE.onboarding.base_url}/?token=${token}`);
        
        setOnboardingLinkText((prev) => ({
            ...prev,
            [rowIndex]: 'Link Copiado!'
        }));

        setTimeout(() => {
            setOnboardingLinkText((prev) => ({
            ...prev,
            [rowIndex]: 'Link Onboarding'
            }));
        }, 2000);
    };


    const verifyUsersJson = () => {
        setLoading(true)
        const newUserField: any = []
        // Object.keys(tempUserCheck).forEach((column, index) => {

        for (const itemList of tempUsersToLoad) {
            let requestObject = {};
            for (let currentColumn of usersListColumns) {
                currentColumn = currentColumn.split('|')
                const currentItemIndex = Object.keys(itemList).indexOf(currentColumn[1])
                if (currentItemIndex >= 0) {
                    requestObject = {
                        ...requestObject,
                        [currentColumn[0]]: itemList[currentColumn[1]]
                    }
                }
                // continue
            }
            const uidx = (new Date().getTime()).toString(36)
            requestObject = {
              ...requestObject,
              "supplierId": supplierId,
              originCompanyId: BASE.COMPANY_DATA.company_id,
              externalId: `bp-${uidx}`,
              gender: 'na',
              document_type: 'na',
              birthDate: "1990-01-01"
            }
            newUserField.push(requestObject)
        }

        console.log('| | | | | ')
        console.log(newUserField)
        console.log('|+ |+ |+ |+ |+ ')


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: eventId,
                supplierId: supplierId,
                userList: newUserField
            })
        };
        fetch(`${BASE.USER.url}${BASE.USER.create_bulk}/${Cookies.get('company')}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                // setTempUserCheck(null)
                setLoading(false)
                setResponseCounter({
                    error: data.countError || 0,
                    success: data.countSuccess || 0,
                    return: true
                })
                setErrorList({
                    simpleList: data.errorData.slice(0, 4),
                    fullList: data.errorData
                })
                getEventUsers()
                getSupplierData(eventId)
                getWaitingList()
            });
    }

    const deleteUserFromList = (selectedIndex: any) => {
        setTempUserCheck(null)
        const _tempUserCheck = tempUserCheck;
        delete _tempUserCheck[selectedIndex]


        setTimeout(() => {
            if (Object.keys(_tempUserCheck).length < 1) {
                return setTempUserCheck(null)
            }
            console.log(selectedIndex)
            console.log(tempUserCheck)
            setTempUserCheck(_tempUserCheck)
        }, 100);
    }

    const selectColumnName = (item: any, column: any, key: any) => {
        const _tempUserListColumns: any = usersListColumns
        const regex = new RegExp(`${item.target.value}|${column}|${key}`);

        if (_tempUserListColumns.length > 0) {
            if (regex.test(_tempUserListColumns.join())) {
                _tempUserListColumns[key] = `${item.target.value}|${column}|${key}`
            } else {
                _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
            }
            setUsersListColumns(_tempUserListColumns)
        } else {
            _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
        }
    }

    const downloadErrorSheet = () => {
        const worksheet = utils.json_to_sheet(errorList.fullList.map(s => ({
            nome: `${s.firstName} ${s.lastName}`,
            documento: s.document,
            email: s.email,
            celular: s.phoneNumber,
            mensagem: s.message
        })));
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `Erros-${eventName?.replace(/ /g, '_')}-${supplierName.replace(/ /g, '_')}.xlsx`);
    };
    

    const removeUsersFromEvent = (userIds: any) => {


        fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}/${eventId}/${supplierId}`,
        {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                  ids: userIds
                })
            }
        )
        .then((res) => res.json())
        .then((response) => {
          
                if(!response) return
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "eventId": eventId,
                        "userId": userIds
                    })
                };
                fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}`, requestOptions)
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        getEventUsers()
                        getSupplierData(eventId)
                    });
                    
            })
            .catch((error) => {
                console.log(error)
                window.location.reload()
            })

    }

    const removeBiometries = (usersId: any) => {

      if(!confirm('Tem certeza?')){
        return false
      }

  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              tokens: usersId
          })
      };
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.remove_token}`, requestOptions)
          .then(response => response.json())

      Swal.fire({
        position: "top",
        icon: "success",
        title: "Biometrias deletadas!",
        showConfirmButton: false,
        timer: 1500
      });
    getEventUsers()

    }


    const inviteBox = (event:any) => {
      const value = event.target.value;
      setSingleUserDocument(value);
    }
    
    
    const searchBox = (event: any) => {
        const value = event.target.value;
        setSearchBar(value);
        setSearchData(null)

        clearTimeout(searchTimeout);

        const timeout = setTimeout(() => {
            getEventUsers(value)
        }, 1000);

        setSearchTimeout(timeout);
    }


    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const handleCloseCreateUserModal = () => {
        setSingleUserDocument('');
        setShowCreateUserModal(false)
        getSupplierData(eventId)
        getWaitingList()
    };
    const handleShowCreateUserModal = () => setShowCreateUserModal(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



  const singleUserHandler = (userDocument:any) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
        document: userDocument
    })
  };

  fetch(`${BASE.USER.url}${BASE.USER.find_users_by_document}`, requestOptions)
  .then(response => response.json())
  .then(data => {

    console.log(data)

    if(data && data.user?.id){
     return inviteSingleuser(data.user)
    }else {
      addUserToWaitinglist(userDocument)
      return handleShowCreateUserModal()
    }


  });
  
  }

  const addUserToWaitinglist = (userDocument:any) => {

    const inviterId = Cookies.get('userId');
    const companyId = Cookies.get('company');
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          document: userDocument,
          eventId,
          groupId: supplierId,
          companyId,
          inviterId
      })
    };
  
    fetch(`${BASE.USER.url}${BASE.USER.waiting_list}`, requestOptions)
    .then(response => response.json())
    .then(data => {
  
      console.log(data)
  
      if(data && data.user?.id){
       return inviteSingleuser(data.user)
      }else {
        return handleShowCreateUserModal()
      }
  
  
    });
    
    }


  const getWaitingList = () => {

    
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
  
    fetch(`${BASE.USER.url}${BASE.USER.waiting_list}/${eventId}/${supplierId}`, requestOptions)
    .then(response => response.json())
    .then(data => {
      setWaitingListCount(data[1])
    });
    
    }


  const inviteSingleuser = (userData) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          eventId: eventId,
          supplierId: supplierId,
          userList: [userData]
      })
  };
  fetch(`${BASE.USER.url}${BASE.USER.create_bulk}/${eventId}`, requestOptions)
      .then(response => response.json())
      .then(data => {
          // setTempUserCheck(null)
          setLoading(false)
          MySwal.fire({
            icon: "success",
            title: "Convidado Incluído!",
          });
          getEventUsers()
          setSingleUserDocument('')
          getSupplierData(eventId)
      });
  }

    const [eventDetail, setEventDetail] = useState<any>(null);
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})

    const dateConvert = (date: any) => {
        const startDateTime = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        setEventDataTransformed({
            dataTransformDay: startDateTime.getDate().toString().padStart(2, '0'),
            dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short" }).format(startDateTime),
            dataTransformTime: `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`,
        })
        return `${startDateTime.toLocaleDateString(
            'pt-br'
        )} ${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`
    }

    const getEventDetail = () => {

        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Erro ao buscar dados do próximo evento');
            }
            return response.json();
          })
          .then((data) => {
            setSelectedEventName(data.event.title)
            setSelectedEventId(data.event.eventCode)
            dateConvert(data.event.startDateTime)
            setEventDetail(formatEventData(data.event))
            getSupplierData(data.event.eventCode)
            getEventUsers()
            getWaitingList()
          })
          .catch((error) => {
            console.error('Erro ao buscar dados do próximo evento:', error);
          });
    }

    const getSupplierData = (selectedEventId: string | null) => {

      fetch(`${BASE.EVENT.url}${BASE.EVENT.event_supplier_status}/${selectedEventId}/${supplierId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Erro ao buscar dados do próximo evento');
          }
          return response.json();
        })
        .then((data) => {
          setSupplierData(data)
        })
        .catch((error) => {
          console.error('Erro ao buscar dados do próximo evento:', error);
        });
  }

    useEffect(() => {
        getEventDetail()
    }, [eventId])

    useEffect(() => {
      initFlowbite()
    }, [page])

    useEffect(() => {
      initFlowbite()
    }, [userCommonList])

    useEffect(() => {
      getEventUsers();
    }, [page, pageSize]);
      

    useEffect(() => {
      if((usersTotal.total + waitingListCount) >= supplierData?.limit){
        return setLimitValidation(true)
      }
      setLimitValidation(false)
    }, [waitingListCount, usersTotal, supplierData]);

    

    const getUserImage = (token: any) => {
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.get_image_biometry}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              token: token
          })
      })
          .then((resg) => resg.json())
          .then((response) => {

              const userImage = response.image ? `data:image/png;base64,${response.image}`:`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=${BASE.onboarding.base_url}/?token=${token}`

              setLoading(false)
              // generateLog({ affectationId: token }, 'download user Image')
              // setCurrentUserImage(response.image)

              MySwal.fire({
                  html:  <img
                  src={userImage}
                  className="qr-code"
                    alt="Pessoa"
                  />,
                  confirmButtonColor: '#ccc',
                })
          })
          .catch((error) => {
              setLoading(false)
              console.log(error)
          })
  }

  const formatTime = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const cabin = {
    seatsNumber: 123,
    name: 'Camarotito',
    id: '402'
  }


  const breadCrumbItens = [
    {
      status: 'active',
      label: 'Convidados',
      link: '#'
    }
  ]
  
    return (
        <>
            <HeaderLine currentPage={'Camarotes'} breadIttems={breadCrumbItens} />
            <div className='all-page sm:ml-64'>
                <CardEventInfo
                  isActive={eventDetail?.isActive || false}
                  eventName={eventDetail?.eventName || ''}
                  eventDescription={eventDetail?.eventDescription || ''}
                  eventDate={eventDetail?.eventDate ? eventDetail.eventDate: ''}
                  eventTime={eventDetail?.eventTime ?  eventDetail.eventTime: ''}
                  eventDayOfWeek={eventDetail?.eventDay ? eventDetail.eventDay : ''}
                />
            
                <div className='all-page-content'>
                    <div className='grid lg:grid-cols-8 md:grid-cols-3 gap-4 mb-4'>
                        <Modal className='dropfile-modal' show={show} onHide={handleClose}>
                            <Modal.Header>
                                <div className="modal-header-content">
                                    <h3>Envie um arquivo</h3>
                                    <i onClick={handleClose} className="modal-close-icon">
                                        <LiaTimesSolid />
                                    </i>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="dropfile-box">
                                    <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="modal-drag-drop">
                                                <input {...getInputProps()} />
                                                <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou clique para selecionar um</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>

                                <a href="#" className="sample-link" onClick={createGuestListExcel}>
                                    <i><LiaFileDownloadSolid /></i>
                                    Baixar exemplo
                                </a>
                            </Modal.Body>
                        </Modal>

                      <div className="button-see-events spacing-default-button ">
                        <DefaultButton {...buttonData} />
                      </div>

                    <div className="event-action-top">
                        {eventDetail && (
                            <div className="event-cabin-card">
                                <Row>
                                <Col className="cabin-info" xs={2}>
                                    <span>Camarote</span>
                                    <h3>{supplierData?.detail?.cabinId || 'N/A'}</h3>
                                    <h2>{supplierData?.detail?.name || 'N/A'}</h2>
                                </Col>

      <Col className="cabin-info" xs={2}>
        <span>Assentos</span>
        <h3>{supplierData?.limit || 'N/A'}</h3>
      </Col>

                                <Col className="cabin-info call-invited" xs={2}>
                                    <span>Convidados</span>
                                    <h3>{supplierData?.userCount}</h3>
                                </Col>
                                <Col onClick={() => {handleShowModal()}} className="cabin-info call-waiting" xs={2}>
                                    <span>
                                    Pendentes
                                    <i><PiEye/></i>
                                    </span>
                                    <h3>{waitingListCount || 0}</h3>
                                </Col>
                                
                                <Col className="cabin-info biometry-call-status" xs={4}>

                                <div className="square-button all-event-buttom rounded-sm">
                                    <span className="big-counter">
                                    <i className='success'><PiSmileyWink /></i>
                                    {supplierData?.biometryCount || '0'}
                                    </span>
                                    <span>Biometrias completas</span>
                                </div>

                                <div className="square-button all-event-buttom rounded-sm">
                                    <span className="big-counter">
                                    <i className="error"><PiSmileyMeh /></i>
                                    {(supplierData?.userCount - supplierData?.biometryCount) || '0'}</span>
                                    <span>Biometrias ausentes</span>
                                </div>
                                </Col>
                                </Row>
                            </div>
                        )}
                    </div>

                    </div>

                    {(tempUserCheck && !responseCounter.return) && (

                        <div className="upload-check-table">
                            <h2 className='table-title'>Verifique os campos e dados enviados.</h2>
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Coluna no Arquivo
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Pré-visualização
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Campo
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(tempUserCheck).map((column, key) => (

                                            <tr key={key} className="bg-white border-b  ">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                    {column}
                                                </th>
                                                <td>
                                                    {tempUserCheck[column]}
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={(item) => selectColumnName(item, column, key)}
                                                    >
                                                        <option>Selecione</option>
                                                        <option value="document">CPF</option>
                                                        <option value="name">Nome</option>
                                                        <option value="email">E-mail</option>
                                                        <option value="phoneNumber">Celular</option>
                                                    </select>
                                                </td>
                                            </tr>

                                        )
                                        )
                                        }


                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className='align-right'>
                                                {loading ? (
                                                    <div className="bar-loader active"></div>
                                                ) : (
                                                    <button onClick={() => verifyUsersJson()} type="button" className="button-default rounded-md">
                                                        Importar
                                                        <svg className='button-icon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2.707 14.293 5.586-5.586a1 1 0 0 0 0-1.414L2.707 1.707A1 1 0 0 0 1 2.414v11.172a1 1 0 0 0 1.707.707Z" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    )
                    }


                    {responseCounter.return &&
                        <>
                            <h2 className='table-title'>Dados enviados com sucesso!</h2>
                            <Row>

                                <Col sm={6}>
                                    {errorList.simpleList.length > 0 && (
                                    <>

                                    <div className="sample-error-list">
                                        <table>
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                                <tr>
                                                    <th scope="col">
                                                        Cadastros com erro
                                                    </th>
                                                    <th scope="col">
                                                        Erro
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {errorList.simpleList &&
                                                    errorList.simpleList.map((item: any, index) => (
                                                        <tr key={index} className="bg-white">
                                                            <th scope="row">
                                                                <div>
                                                                    <div className="text-base font-semibold">{item.name || ''}</div>
                                                                    <div className="font-normal text-gray-500">{item.document || ''}</div>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {item.message}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} className='align-right'>
                                                        <button onClick={() => downloadErrorSheet()} type="button" className="button-default btn-info rounded-md">
                                                            Exportar erros
                                                            <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1.707 2.707 5.586 5.586a1 1 0 0 0 1.414 0l5.586-5.586A1 1 0 0 0 13.586 1H2.414a1 1 0 0 0-.707 1.707Z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    </>
                                    )}
                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers error-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.error || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas com erro no cadastro!
                                        </p>
                                    </div>


                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.success || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas cadastradas com sucesso!
                                        </p>
                                    </div>

                                </Col>
                            </Row>



                        </>

                    }

<div className="people-table">
  


    <Row className="table-action-bar">
    <Col xs={8}>
      {limitValidation ? (
        <div className="invite-text-warning">
          <h2>Seus convites acabaram!</h2>
          <p>O limite para convidados foi atingido. Revise a lista de convidados caso queira convidar outras pessoas.</p>
        </div>
      ):(
        
        <div className="action-invite">
        <div className="table-action-invite">
          <i>
            <PiUserCircleLight/>
          </i>
          <input
                onChange={inviteBox}
                value={singleUserDocument}
                type="text"
                id="table-invite-users"
                placeholder="Informe um CPF para convidar"
                />
        </div>
        <button onClick={() => singleUserHandler(singleUserDocument)} type="button" className="button-default rounded-md">
              Convidar
          </button>

          <div className='event-action-top-buttons'>

{/* <div onClick={() => downloadTickets(supplierId)} className="square-button call-buttom rounded-sm">
        <i className='custom-icon'>
            <MdOutlineFileDownload />
        </i>
        <span>Relatório</span>
    </div> */}


    <div onClick={handleShow} className="inline-button call-buttom rounded-sm">
        <i className='custom-icon'>
            <PiUserCirclePlus />
        </i>
        <span>Convidar em massa</span>
    </div>


</div>
      </div>
    
     )}
        </Col>
<Col xs={4}>
<h2 className='table-title invite-title'>Pessoas convidadas</h2>
      <div className="table-action-search">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <i>
          <PiMagnifyingGlassLight/>
        </i>
        <input onChange={searchBox} type="text" id="table-search-users" placeholder="Buscar usuários (Nome ou CPF)" />
      </div>
      </Col>
    </Row>
    

    
    <Table>
        <thead >
            <tr>
             {/* <th scope="col">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500    focus:ring-2  "/>
                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                </th>  */}
                <th className='col col2' scope="col">
                   <div className="header-content">
                    <span>Dados</span>
                   </div>
                </th>
                {/* <th className='col col4' scope="col">
                <div className="header-content">
                    <span>VIP</span>
                   </div>
                </th> */}
                <th className='col col2' scope="col">
                <div className="header-content">
                    <span>Biometria</span>
                   </div>
                </th>
                <th className='col col2' scope="col">
                <div className="header-content">
                    <span>E-mail</span>
                   </div>
                </th>
                <th colSpan={2} className='col col3' scope="col">
                <div className="header-content">
                    <span>Ação</span>
                   </div>
                </th>
            </tr>
        </thead>
        <tbody >
        {userCommonList?.length > 0 ? (
           userCommonList.map((item, rowIndex) => (
              <tr key={item.id}>
               {/* <td>
                     <div className="flex items-center">
                        <input checked={userIdHandlerList.indexOf(item.id) >= 0 ? true:false} onChange={(e) => checkHandler(e, item.id)} id={item.id} type="checkbox" />
                        <label htmlFor={item.id} className="sr-only">checkbox</label>
                    </div> 
                </td>*/}
                <td scope="row" className="table-people-info">
                    {/* <img src={faceOK} alt={item.firstName} /> */}
                    <img  src={item.biometric ? faceOK:faceNOK} alt={item.firstName} />
                    <div>
                        <h3>{item.firstName} {item?.lastName ? item.lastName : ''}</h3>
                        <h4>Documento: {item.document}</h4>
                    </div>  
                </td>

                {/* <td className='col4 text-center'>
                    {item.externalId || 'N/A'}
                </td> */}
                <td className='col2  text-center'>
                    <i className={`status-circle ${item.biometric ? 'ok':'nok'}`}>
                      </i> {item.biometric ? 'Completa':'Ausente'}
                </td>

                
               {/*  <td>
                {item.accessCode || 'N/A'}
                </td> */}
                <td scope="row" className="text-center">
                    <div>
                        <span>{item?.email ? item.email : ''}</span>
                    </div>  
                </td>
                <td className='table-people-single-action col3  text-center'>
                    <p className='share-text'>enviar onboaridng:</p>
                {/* <button onClick={() => sendMessage([item.id], 'whats')} type="button" className="button-default button-share rounded-md">
                    whatsapp
                </button> */}
                {/* <button  onClick={() => sendMessage([item.hikId], 'email')} type="button" className="button-default button-share rounded-md">
                    e-mail
                </button> */}
                <button  onClick={() => getOnboardingLink(item.token, rowIndex)} type="button" className="button-default button-share rounded-md">
               
                                                    {onboardingLinkText[rowIndex] || 'Link Onboarding'}
                </button> 
                </td>
                <td>
                    <button onClick={() => removeUsersFromEvent([item.hikId])} className='fake-link soft-exclude'>
                        excluir
                    </button>
                </td>
            </tr>
           ))):(
            <tr>
                <td colSpan={8}>
                <div className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 text-center" role="alert">
                    <span className="font-medium">Nenhuma pessoa encontrada para o evento.</span> 
                </div>
                </td>
            </tr>
           )
            }
        </tbody>
    </Table>

    <div>
    {userCommonList?.length > 0 && (
        <DefaultPagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={(newPage) => {
            setPage(newPage);
            getEventUsers(); 
        }}
        totalRecords={usersTotal.total}
    />

    )}

    </div>

    <Modal show={showCreateUserModal} onHide={handleCloseCreateUserModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Convite Individual</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className='text-info-modal'>
                    Como a pessoa que você está convidando ainda não tem cadastro conosco, envie o link abaixo para ela e, após o cadastro ela poderá acessar o evento!<br/><br/>
                    Mas não se preocupe, já guardamos o lugar de seu convidado!<br/><br/>
                    <a href={BASE.onboarding.base_url} rel="noreferrer" target="_blank">{BASE.onboarding.base_url}</a>
                    <i className="copy-link-cat" onClick={() => copyToClipboard(BASE.onboarding.base_url)}>
                        <PiLink />
                        copiar link
                    </i>
                  </p>
                </Modal.Body>
            </Modal>
    {/* <!-- Edit user modal  tabIndex="-1" --> 
    <div id="editUserModal"   className="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative w-full max-w-2xl max-h-full">
            <form action="#" className="relative bg-white rounded-lg shadow ">
                <div className="flex items-start justify-between p-4 border-b rounded-t ">
                    <h3 className="text-xl font-semibold text-gray-900 ">
                        Edit user
                    </h3>
                   <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center  " data-modal-hide="editUserModal">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 ">First Name</label>
                            <input type="text" name="first-name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Bonnie" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 ">Last Name</label>
                            <input type="text" name="last-name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Green" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">E-mail</label>
                            <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="example@company.com" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                            <input type="number" name="phone-number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="e.g. +(12)3456 789" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="department" className="block mb-2 text-sm font-medium text-gray-900 ">Department</label>
                            <input type="text" name="department" id="department" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Development" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 ">Company</label>
                            <input type="number" name="company" id="company" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="123456" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="current-password" className="block mb-2 text-sm font-medium text-gray-900 ">Current Password</label>
                            <input type="password" name="current-password" id="current-password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="••••••••" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="new-password" className="block mb-2 text-sm font-medium text-gray-900 ">New Password</label>
                            <input type="password" name="new-password" id="new-password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="••••••••" />
                        </div>
                    </div>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ">
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">Save all</button>
                </div>
            </form>
        </div>
    </div>
    */}
</div>
  
                </div>
            </div>
            <ModalSelectEvent show={showModalEventList} onHide={handleHideModalEventList} setEventId={setSelectedEventId}/>
            <ModalShowWaitingList eventId={(eventId || '')} groupId={supplierId} show={showModal} onHide={handleHideModal}/>
        </>
    )
}
