import React, { useEffect, useState } from "react";
import "./DropDownProfileSelection.sass";
import { IoIosArrowDown } from "react-icons/io";

interface Supplier {
  id: string;
  name: string;
  cabinId: string; 
}

interface DropDownProfileSelectionProps {
  suppliersList: Supplier[];
  userName: string;
  profile: string;
  handleSelection: (profile: any) => void;
}

const DropDownProfileSelection: React.FC<DropDownProfileSelectionProps> = ({
  suppliersList,
  userName,
  profile,
  ...props
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [multipleRoles, setMultipleRoles] = useState(false);
  const [profileSelected, setProfileSelected] = useState(profile);

  const handleShowDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    if (suppliersList && suppliersList.length > 1) {
      setMultipleRoles(true);
    }
  }, [suppliersList]);

  return (
    <div className="dropdown-container">
      <ul className={`dropdown-wrapper ${showDropDown ? 'box-shadow' : ''}`}>
        <div className="dropdown-user-data-content">
          <span className="user-name">{userName}</span>
          <div className="dropdown-profile-data">
            {showDropDown ? (
              <span className='wait-selection'>Selecione</span>
            ):(
              <span>{profileSelected}</span>
            )}
            {multipleRoles && (
              <div onClick={handleShowDropdown} className="pointer">
                <IoIosArrowDown />
              </div>
            )}
          </div>
        </div>
        {showDropDown &&
          suppliersList.map((supplier) => (
            <li
              key={supplier.id}
              className="dropdown-selection pointer"
              onClick={() => props.handleSelection({
                id: supplier.id,
                name: supplier.name,
                cabinId: supplier.cabinId
              })}
            >
              {supplier.cabinId 
                ? (supplier.name 
                    ? `${supplier.cabinId} - ${supplier.name}` 
                    : supplier.cabinId) 
                : (supplier.name || 'Sem nome')
              }
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DropDownProfileSelection;


