import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap'
import './Cabins.sass'
import HeaderLine from '../../shared/header'
import { IoMdAddCircleOutline } from 'react-icons/io'
import IconButton from '../../dashboard-custom-components/components/Button/IconButton/IconButton'
import DefaultSearchField from '../../dashboard-custom-components/components/SearchField/DefaultSearchField'
import { BASE } from '../../basedata/constants'
import Cookies from 'js-cookie'
import { CONFIG } from '../../config/configVariables'
import CabinRow from '../components/CabinRow'
import { MdOutlineStadium } from 'react-icons/md'
import ModalNoData from '../../dashboard-custom-components/components/Modal/ModalNoData'
import DefaultPagination from '../../dashboard-custom-components/components/Pagination/DefaultPagination'
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner'
import ModalIncludeCabin from '../components/modalIncludeCabin'

function Cabins() {
  const companyId = Cookies.get('company')

  const pageSize = 10
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [search, setSearch] = useState('')

  const [showNoDataModal, setShowNoDataModal] = useState(false)
  const [showDefaultModal, setShowDefaultModal] = useState(false) 
  const [hasCabin, setHasCabin] = useState(false)
  const [cabins, setCabins] = useState([
    {
      id: '',
      name: '',
      status: '',
      isActive: false,
      seats: 0,
      cabin_id: '',
      gate: '',
      section: ''
    }
  ])
  const [loading, setLoading] = useState(false) 

  const breadCrumbItens = [
    {
      status: 'active',
      label: 'Camarotes',
      link: '#'
    }
  ]

  const buttonArgs = {
    label: 'Incluir Camarotes',
    icon: <IoMdAddCircleOutline size={20} />
  }

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    setPage(1);
    fetchAllCabins(searchTerm);
  };

  const fetchCabins = async (searchTerm = '', page = 1, checkAllPages = false) => {
    setLoading(true);
    const requestOptions = {
      method: searchTerm ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: searchTerm ? JSON.stringify({ searchTerm, companyId }) : null
    };
  
    const url = searchTerm
      ? `${BASE.USER.url}${BASE.USER.supplier_find}?page=${page}&pageSize=${pageSize}`
      : `${BASE.USER.url}${BASE.USER.company_suppliers}/${companyId}?page=${page}&pageSize=${pageSize}`;
  
    try {
      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        setShowNoDataModal(true);
        return null; 
      } else {
        const json = await response.json();
        if (json) {
          const cabins = json.suppliers;
          calculateAndSetTotaPages(json);
  
          if (cabins.length > 0) {
            setCabins(cabins);
            setHasCabin(true);
          } else if (checkAllPages) {
            setShowNoDataModal(true); 
          }
  
          return cabins;
        }
      }
    } catch (error) {
      console.log(error);
      return null; 
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCabins = async (searchTerm = '') => {
    let pageToSearch = 1;
    let totalFound = false;
  
    while (pageToSearch <= totalPages && !totalFound) {
      const cabins = await fetchCabins(searchTerm, pageToSearch, true);
      if (cabins && cabins.length > 0) {
        totalFound = true;
        setPage(pageToSearch); 
      }
      pageToSearch++;
    }
  
    if (!totalFound) {
      setShowNoDataModal(true); 
    }
  };

  const calculateAndSetTotaPages = (json: any) => {
    const totalData = Number(json.total)
    setTotal(totalData)

    const totalOfPages = totalData / pageSize

    if (totalData % pageSize) {
      setTotalPages(Math.floor(totalOfPages + 1))
    } else {
      setTotalPages(totalOfPages)
    }
  }

  const handleAddCabin = () => {
    setShowDefaultModal(true); 
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    fetchCabins(search, page);
  }, [search, page]);

  useEffect(() => {
    fetchCabins();
  }, []);

  return (
    <>
      <div className='cabins-container'>
        <HeaderLine currentPage={'Camarotes'} breadIttems={breadCrumbItens} />

        {loading ? (
          <div className='loading-cabins-container'>
            <LoadingSpinner /> 
          </div>
        ) : hasCabin ? (
          <>
            <Row className="cabin-actions">
              <Col xs={6}>
                <span>Lista de Camarotes</span>
              </Col>

              <Col xs={2}>
                <IconButton handleOnClick={handleAddCabin} {...buttonArgs} />
              </Col>

              <Col xs={4}>
                <div className="cabin-form">
                  <Form className='cabin-form-search-field-box'>
                    <DefaultSearchField
                      placeHolder={'Pesquisar nome ou número do camarote'}
                      handleSearch={handleSearch}
                    />
                  </Form>
                </div>
              </Col>
            </Row>

            <Col></Col>
            <Col>
              <Row>
                <div className="cabin-list">
                  {cabins &&
                    cabins.map((cabin) => (
                      <div key={cabin.id}>
                        <CabinRow
                          cabinId={cabin.cabin_id}
                          name={cabin.name}
                          seatsNumber={cabin.seats}
                          isActive={cabin.isActive}
                          location={
                            cabin.gate 
                              ? `Portão ${cabin.gate} - Setor ${cabin.section}` 
                              : `Setor ${cabin.section}`
                          }  
                          section = {cabin?.section}                    
                          supplierId={cabin.id}
                          companyId={companyId}
                        />
                      </div>
                    ))}
                </div>
              </Row>
              <Row>
                <DefaultPagination
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  totalRecords={total}
                />
              </Row>
            </Col>
            <ModalNoData
              show={showNoDataModal}
              onHide={() => {
                setShowNoDataModal(false)
                setSearch('')
              }}
              title={'Pesquisa sem resultados'}
            >
              <p>O camarote pesquisado não foi encontrado.</p>
            </ModalNoData>

            <ModalIncludeCabin
              show={showDefaultModal}
              onHide={() => setShowDefaultModal(false)} 
            />

          </>
        ) : (
          <div className="parent-container">
            <div className="background">
              <MdOutlineStadium className="icon" />
            </div>
            <div>
              <p className="dashboard-text">Não há camarotes cadastrados.</p>
              <p className="dashboard-text-office">
                <a href="#" className="gradient-text" onClick={handleAddCabin}>
                  Clique aqui
                </a>{' '}
                para cadastrar um camarote.
              </p>
            </div>
          </div>
        )}

        <ModalIncludeCabin
          show={showDefaultModal}
          onHide={() => setShowDefaultModal(false)} 
        />
      </div>
    </>
  )
}

export default Cabins
