import React from "react";
import { FiEdit } from "react-icons/fi";
import "./EditDataButton.sass";

interface EditDataButtonProps {
  handleOnClick: () => void;
  label: string;
  disabled?: boolean
}

const EditDataButton: React.FC<EditDataButtonProps> = ({
  handleOnClick,
  label,
  disabled
}) => {
  return (
    <button 
      onClick={handleOnClick} 
      className="container-edit-data-button"
      disabled={disabled}
    >
      {label}
      <span>
        <FiEdit />
      </span>
    </button>
  );
};

export default EditDataButton;