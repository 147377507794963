import React, { useState, useEffect } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import AddDataButton from '../../dashboard-custom-components/components/Button/AddDataButton/AddDataButton';
import './modalCreateCabinManagers.sass';
import { BASE } from '../../basedata/constants';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';
import Cookies from 'js-cookie'

interface ModalCreateCabinManagersProps {
    show: boolean;
    onHide: () => void;
    id: string;
    cabinId: string;
    cabinName: string;
}

const ModalCreateCabinManagers: React.FC<ModalCreateCabinManagersProps> = ({ show, onHide, id, cabinId, cabinName }) => {
    const [managerName, setManagerName] = useState('');
    const [managerCpf, setManagerCpf] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [managerPassword, setManagerPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        const [firstName, ...rest] = managerName.split(' ');
        const lastName = rest.join(' ') || '';
    
        const userData = {
            firstName,
            lastName,
            email: managerEmail,
            phoneNumber: '',  
            document: managerCpf,
            document_type: 'CPF',
            originCompanyId: Cookies.get('company'),
            externalId: 'provider',
            gender: 'na',  
            birthDate: '1990-01-01'  
        };
    
        setLoading(true);
    
        fetch(`${BASE.USER.url}${BASE.USER.create_user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify(userData)
        })
        .then((res) => res.json())
        .then((userResponse) => {
            const { sup } = userResponse;  

            const companyName = cabinName ? `${cabinName} - ${cabinId}` : cabinId; 
            const companyData = {
                name: managerName,
                recoveryKey: '12345678945', 
                email: managerEmail,
                login: managerEmail,
                role: 'coordinator',
                companyId: id,
                userId: sup,
                companyName
            };
    
            return fetch(`${BASE.COMPANY.url}${BASE.COMPANY.create_coordinator}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(companyData)
            });
        })
        .then((res) => {
            if (res.ok) {
                window.location.reload(); 
            } else {
                console.log('Failed to create coordenador:', res.statusText);
                throw new Error('Failed to create coordenador');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .finally(() => {
            setLoading(false);
            onHide(); 
        });
    };
    
    const handleAddDataClick = () => {
        handleSubmit();
    };

    const isFormValid = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return (
            managerName.trim() !== '' &&
            managerCpf.trim() !== '' &&
            emailPattern.test(managerEmail)
        );
    };

    return (
        <LargeModal show={show} onHide={onHide} title="Incluir Gestor">
            <div className="container-modal-include-cabin-managers">
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        className="custom-form"
                    >
                        <div className="form-group form-group-first">
                            <label className="form-label">Nome</label>
                            <input
                                type="text"
                                value={managerName}
                                onChange={(e) => setManagerName(e.target.value)}
                                maxLength={50}
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">CPF</label>
                            <input
                                type="text"
                                value={managerCpf}
                                onChange={(e) => setManagerCpf(e.target.value)}
                                maxLength={11}
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                value={managerEmail}
                                onChange={(e) => setManagerEmail(e.target.value)}
                                maxLength={50}
                                className="form-control"
                            />
                        </div>

                        <div className="container-button">
                            <div className="container-modal-edit-event-button">
                                <AddDataButton
                                    handleOnClick={handleAddDataClick}
                                    label="INCLUIR"
                                    disabled={!isFormValid()}
                                />
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LargeModal>
    );
};

export default ModalCreateCabinManagers;





