import React from "react";
import './ActiveStatus.sass';

import { IoCloseOutline } from "react-icons/io5";
import { BsCheck2 } from "react-icons/bs";

interface ActiveStatusProps {
  status: boolean; 
}

const ActiveStatus: React.FC<ActiveStatusProps> = ({ status }) => {


  return (
    <div className="container-active-status">
      <span>
        {status ? (
          <div className="status-content">
            <BsCheck2 className="status-icon-active" />
            <span>Ativo</span>
          </div>
        ) : (
          <div className="status-content">
            <IoCloseOutline className="status-icon-inactive"/>
            <span>Inativo</span>
          </div>
        )}
      </span>
    </div>
  );
};

export default ActiveStatus;
