import React from 'react'
import Cookies from 'js-cookie'
import DropDownProfileSelection from '../../dashboard-custom-components/components/DropDown/DropDownProfileSelection/DropDownProfileSelection'
import { useNavigate } from 'react-router-dom'

const ProfileSelection = () => {

  const navigate = useNavigate()
  
  const parseListJsonCookie = (cookie: any) => {
    try {
      return JSON.parse(Cookies.get(cookie))
    } catch (e) {
      return []
    }
  }

  const suppliersList = parseListJsonCookie('suppliersList')
  const roleList = parseListJsonCookie('roleList')
  const userName = Cookies.get('name') || 'Nome não encontrado'
  const profile = Cookies.get('profile') || null
  const cabinId = Cookies.get('cabinId') || ''

  let profileMessage;
  if (cabinId === null && userName !== 'Nome não encontrado') {
    profileMessage = 'Administrador'
  } else if (cabinId && profile) {
    profileMessage = `${cabinId} - ${profile}`
  } else if (cabinId) {
    profileMessage = cabinId
  } else {
    profileMessage = profile || 'Perfil não encontrado'
  }

  const handleProfileSelection = (data) => {
    const selectedRole = roleList.find((role) => role.companyId === data.id)
    const selectedProfile = data.name
    const selectedCabinId = data.cabinId || ''

    const companyId = selectedRole ? selectedRole.companyId : '';

    Cookies.set('role', JSON.stringify(selectedRole), { path: '/' })
    Cookies.set('profile', selectedProfile, { path: '/' })
    Cookies.set('cabinId', selectedCabinId, { path: '/' })
    Cookies.set('company', companyId, { path: '/' }) 

    navigate('/dashboard')
    window.location.reload()
  }

  return (
    <DropDownProfileSelection
      suppliersList={suppliersList}
      userName={userName}
      profile={profileMessage}
      handleSelection={handleProfileSelection}
    />
  )
}

export default ProfileSelection



