import React from "react";
import { Modal } from "react-bootstrap";
import "./DefaultModal.sass";

interface DefaultModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    children: React.ReactNode;
}

const DefaultModal: React.FC<DefaultModalProps> = ({ show, onHide, title, children }) => {
    return (
        <Modal show={show} onHide={onHide} size="xl" className="custom-default-modal">
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title className="custom-modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default DefaultModal;
