import React from "react";
import "./IconButton.sass";

interface IconButtonProps {
  handleOnClick: () => void;
  label: string;
  icon: React.ReactElement;
  className?: string
}

const IconButton: React.FC<IconButtonProps> = ({
  handleOnClick,
  label,
  icon,
  ...props
}) => {
  return (
    <button onClick={handleOnClick} className={`button-wrapper ${props.className}`}>
      {label}
      {icon}
    </button>
  );
};

export default IconButton;
