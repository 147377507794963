import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Table,
    Modal,
    ButtonToolbar,
    ButtonGroup
} from 'react-bootstrap'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import './accessLog.sass'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";


interface Props {
    supplierId: any,
    companyId: any
}

export function CoordinatorRow({ supplierId, companyId }: Props) {

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [show, setShow] = useState(false);

    const [currentCoordinator, setCurrentCoordinator] = useState<any>({});


    const handleClose = () => {
        setShow(false)
        setCurrentCoordinator({})
    };
    const handleShow = (coordinator: any) => {
        setCurrentCoordinator(coordinator);
        reset({
            id: coordinator.id,
            name: `${coordinator.firstName} ${coordinator.lastName}`,
            email: coordinator.email,
            phoneNumber: coordinator.phoneNumber
        });
        setShow(true);
    };

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => {
        setShowConfirm(false)
        setCurrentCoordinator({})
    }

    const [coordinatorList, setCoordinatorList] = useState<any[]>([])


    const getCoordinator = () => {

        console.log('mamaeu', supplierId, companyId)

        fetch(`${BASE.USER.url}${BASE.api_user.coordinator_search}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                originCompanyId: companyId,
                supplierId: supplierId
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setCoordinatorList(response.coordinators)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const editCoordinator = (data: any) => {

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')
        fetch(`${BASE.USER.url}${BASE.api_user.user_edit}/${data.id}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                phoneNumber: data.phoneNumber
                    .replace(/"/g, '')
                    .replace(/'/g, '')
                    .replace(/\(|\)/g, ''),
            })
        })
            .then((res) => res.json())
            .then((response) => {
                response
            })
            .catch((error) => {
                console.log(error)
            })


            fetch(`${BASE.COMPANY.url}${BASE.COMPANY.get_role_by_userId}/${data.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                }
            })
                .then((res) => res.json())
                .then((response) => {
                    console.log()
    
                    const firebaseId = response.coordinator[0].firebaseId
    
                    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.get_user_by_userId}/${firebaseId}`, {
                        method: 'Put',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': BASE.api.api_key
                        },
                        body: JSON.stringify({
                            displayName: data.name,
                            email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                            phoneNumber: data.phoneNumber
                                .replace(/"/g, '')
                                .replace(/'/g, '')
                                .replace(/\(|\)/g, ''),
                        })
                    })
                        .then((res) => res.json())
                        .then((response) => {
                            window.location.reload();
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })

    }

    const excludeCoordinator = (data: any) => {

        fetch(`${BASE.USER.url}${BASE.api_user.user_edit}/${data.id}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                isActive: false
            })
        })
            .then((res) => res.json())
            .then((response) => {
                response
            })
            .catch((error) => {
                console.log(error)
            })

        fetch(`${BASE.COMPANY.url}${BASE.COMPANY.get_role_by_userId}/${data.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                console.log()

                const firebaseId = response.coordinator[0].firebaseId

                fetch(`${BASE.COMPANY.url}${BASE.COMPANY.delete_firebase_user}/${firebaseId}`, {
                    method: 'Delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': BASE.api.api_key
                    },
                })
                    .then((res) => res.json())
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getCoordinator()
    }, [])

    if (!coordinatorList) {
        return (<></>)
    }
    return (
        <ul className='coordinator-list'>
            {coordinatorList &&
                coordinatorList.map((item, index) => (
                    <li key={index} className='cordinator-row'>

                        <ul className="group-action">
                            <li>
                                <span onClick={() => handleShow(item) } className="sidebar-icon"><AiOutlineForm /></span>
                            </li>
                            <li>
                                <span onClick={() => { setCurrentCoordinator(item), handleShowConfirm() }} className="sidebar-icon icon-red"><AiOutlineMinusCircle /></span>
                            </li>
                        </ul>

                        <p>{`${item.firstName} ${item.lastName}`} | {item.email} | {item.phoneNumber}</p>
                    </li>
                ))}
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(editCoordinator)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={currentCoordinator.id}
                        {...register('id')}
                    />
                    <Form.Control
                        type="hidden"
                        defaultValue="true"
                        {...register('operator')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Editar coordenador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentCoordinator.firstName} ${currentCoordinator.lastName}`}
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    defaultValue={currentCoordinator.email}
                                    {...register('email', { required: true })}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    placeholder="Telefone"
                                    defaultValue={currentCoordinator.phoneNumber}
                                    {...register('phoneNumber')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>


                        <ButtonToolbar
                            className="justify-content-between"
                            aria-label="Toolbar with Button groups"
                        >
                            <ButtonGroup aria-label="Last group">
                                <Button variant="default" onClick={handleClose}>
                                    Fechar
                                </Button>
                                <Button variant="success" type='submit'>
                                    Salvar
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Body>
                </Form>

            </Modal>



            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza que deseja excluir {currentCoordinator.lastName}?
                </Modal.Body>
                <Modal.Footer>
                    <Form id="exclude" onSubmit={handleSubmit(excludeCoordinator)}>

                        <Button variant="secondary" onClick={handleCloseConfirm}>
                            Sair
                        </Button>
                        <Button variant="danger" onClick={() => excludeCoordinator(currentCoordinator)}>
                            Excluir
                        </Button>
                    </Form>
                </Modal.Footer>
            </Modal>
        </ul>
    )
}