import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import './header.sass'
import BreadCrumb from '../dashboard-custom-components/components/BreadCrumb/BreadCrumb';
import { Col, Row } from 'react-bootstrap';
import ProfileSelection from '../views/components/ProfileSelection';
import { BASE } from '../basedata/constants';

interface BreadItem {
  status: string;
  label: string;
  link: string;
}

export interface HeaderProps {
  currentPage: string;
  breadIttems: BreadItem[];
}

const HeaderLine: React.FC<HeaderProps> = ({ currentPage, breadIttems = [] }) => {
  
  return (
    <Row className="header-wrapper m-3">
      <Col xs={10} className="ps-0">
        <h1>Camarotes {BASE.COMPANY_DATA.company_name}</h1>
        <BreadCrumb navItems={breadIttems}/>
      </Col>
      <Col xs={2} className="pe-0">
        <ProfileSelection/>
      </Col>
    </Row>

  );
};


export default HeaderLine;