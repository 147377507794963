import React from "react";
import { Modal } from "react-bootstrap";
import iconNoData from "../../../images/icon_no_data.svg";
import "./ModalNoData.sass";

interface ModalNoDataProps {
    show: boolean;
    onHide: () => void;
    title: string;
    children: React.ReactNode;
}

const ModalNoData: React.FC<ModalNoDataProps> = ({ show, onHide, title, children }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" className="custom-modal">
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title className="custom-modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <div className="icon-container">
                    <img src={iconNoData} alt="Icon" />
                </div>
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default ModalNoData;