import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';

registerLocale('pt-BR', ptBR);

interface DateTimePickerProps {
  selectedDate: Date | null;
  onChangeDate: (date: Date | null) => void;
  className?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({ selectedDate, onChangeDate, className }) => {
  return (
    <div className="form-group">
      <DatePicker
        selected={selectedDate}
        onChange={(date: Date | null) => onChangeDate(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={5}
        dateFormat="Pp"
        locale="pt-BR"
        className={className}
        placeholderText="Selecione"
        timeCaption="Hora"
      />
    </div>
  );
};

export default DateTimePicker;
