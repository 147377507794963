import React, { useState, useEffect } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import EditDataButton from '../../dashboard-custom-components/components/Button/EditDataButton/EditDataButton';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';
import './modalEditCabin.sass';
import Cookies from 'js-cookie'; 
import { BASE } from '../../basedata/constants';
import DropdownSelect from '../../dashboard-custom-components/components/DropdownSelect/DropdownSelect';

interface ModalEditCabinProps {
  show: boolean;
  onHide: () => void;
  initialData: {
    cabinNumber: string;
    cabinName: string;
    cabinId: string;  
    seatsNumber: string;
    sector: string;
    section: string;
  };
}

const ModalEditCabin: React.FC<ModalEditCabinProps> = ({ show, onHide, initialData }) => {
  const [cabinNumber, setCabinNumber] = useState('');
  const [cabinName, setCabinName] = useState('');
  const [seatsNumber, setSeatsNumber] = useState('');
  const [sector, setSector] = useState('');
  const [sections, setSections] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCabinNumber(initialData.cabinNumber);
    setCabinName(initialData.cabinName);
    setSeatsNumber(initialData.seatsNumber);
    setSector(initialData.section);
  }, [initialData]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const companyId = Cookies.get('company');

        if (!companyId) {
          console.error('Company ID not found in cookies');
          return;
        }

        const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.list_distributions}/${companyId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (response.ok) {
          const sections = result.distribution.map((item: any) => item.section);
          setSections(sections);
        } else {
          console.error("Error fetching sections:", result.message || 'Unknown error');
        }
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    fetchSections();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log({
      cabinNumber,
      cabinName,
      seatsNumber,
      sector,
    });
    onHide();
  };

  const handleAddDataClick = async () => { 
    setLoading(true);
    const companyId = Cookies.get('company'); 

    if (!companyId) {
        console.error('Company ID not found in cookies');
        setLoading(false);
        return;
    }

    const requestBody = {
        section: sector,
        seats: seatsNumber,
        name: cabinName
    };

    try {
        const response = await fetch(`${BASE.USER.url}${BASE.USER.supplier}/${initialData.cabinId}`, { 
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
        
        const result = await response.json();

        if (response.ok) {
            console.log("Data added successfully:", result);
            window.location.reload(); 
        } else {
            console.error("Error adding data:", result.message || 'Unknown error');
        }
    } catch (error) {
        console.error("Error adding data:", error);
    } finally {
        setLoading(false);
        onHide();
    }
  };

  return (
    <LargeModal show={show} onHide={onHide} title="Editar Camarote">
      {loading && (
          <div className="loading-spinner-container">
              <LoadingSpinner />
          </div>
      )}
      <div className="container-modal-edit-cabin">
        <form onSubmit={handleSubmit} className="custom-form">
          <div className="form-group form-group-first">
            <label className="form-label">Número do Camarote</label>
            <input
              type="text"
              value={cabinNumber}
              onChange={(e) => setCabinNumber(e.target.value)}
              disabled
              required
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Nome do Camarote 
              <span className="form-label-detail"> (opcional)</span> 
            </label>
            <input
              type="text"
              value={cabinName}
              onChange={(e) => setCabinName(e.target.value)}
              placeholder={initialData.cabinName}
              className="form-control"
              maxLength={20} 
            />
          </div>

          <div className="form-group">
            <label className="form-label">Quantidade de Assentos</label>
            <input
              type="number"
              value={seatsNumber}
              onChange={(e) => setSeatsNumber(e.target.value)}
              placeholder={initialData.seatsNumber}
              required
              min="1" 
              title=''
              max="9999" 
              className="form-control"
            />
          </div>

          <div className="form-group form-group-last">
              <label className="form-label">Setor</label>
              <DropdownSelect 
                  className="form-control-2"
                  items={sections.map((section) => ({ id: section, name: section }))}
                  selectedId={sector}
                  onSelect={setSector}
                  labelKey="name"
              />
          </div>

          <div className="container-button">
            <div className="container-modal-edit-data-button">
                <EditDataButton handleOnClick={handleAddDataClick} label="EDITAR" />
            </div>
          </div>
        </form>
      </div>
    </LargeModal>
  );
};

export default ModalEditCabin;







