import React, { useState } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import './modalDeleteCabin.sass';
import DeleteDataButton from '../../dashboard-custom-components/components/Button/DeleteDataButton/DeleteDataButton';
import { BASE } from '../../basedata/constants';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

interface ModalDeleteCabinProps {
    show: boolean;
    onHide: () => void;
    cabinNumber: string;
    cabinName: string;
    supplierId: string;
}

const ModalDeleteCabin: React.FC<ModalDeleteCabinProps> = ({ show, onHide, cabinNumber, cabinName, supplierId }) => {   
    const [loading, setLoading] = useState(false);

    const handleDeleteDataClick = () => {
        setLoading(true);  
        fetch(`${BASE.USER.url}${BASE.USER.supplier}/${supplierId}`, {
            method: 'DELETE',
        })
        .then((resg) => resg.json())
        .then((response) => {
            console.log("Supplier deleted successfully:", response);
            setLoading(false); 
            onHide(); 
            window.location.reload(); 
        })
        .catch((error) => {
            console.error("Error deleting supplier:", error);
            setLoading(false); 
        });
    };

    return (
        <LargeModal show={show} onHide={onHide} title="Excluir Camarote">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className='container-modal-delete-cabin'>

                <p> Você deseja excluir o camarote <span> {cabinNumber} - {cabinName} </span> ? </p>
                <div className='container-delete-button'>
                    <div className='container-modal-delete-data-button'>
                        <DeleteDataButton handleOnClick={handleDeleteDataClick} label="EXCLUIR" />
                    </div>
                </div>

            </div>
        </LargeModal>
    );
}

export default ModalDeleteCabin;

