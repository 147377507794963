import React from 'react';
import './ProgressBar.sass';

interface ProgressBarProps {
  percent: number;
}
const ProgressBar: React.FC<ProgressBarProps> = ({ percent}) =>{

// const ProgressBar = ({ percent }) => {
  const progressClass = percent < 40 ? 'progress-bar green' : 'progress-bar green';

  return (
    <div className="progress-container">
      <div 
        className={progressClass} 
        style={{ width: `${percent}%` }}>
        {/* {percent}% */}
      </div>
    </div>
  );
};

export default ProgressBar;