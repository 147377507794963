import React, { ChangeEvent, useState } from 'react'
import './DefaultSearchField.sass'
import { GoSearch } from 'react-icons/go'
import { FaTimes } from 'react-icons/fa'

interface DefaultSearchFieldProps {
  placeHolder: string;
  handleSearch: (searchTerm: string) => void
}

const DefaultSearchField: React.FC<DefaultSearchFieldProps> = ({
  placeHolder,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [showCleanFieldButton, setShowCleanFieldButton] = useState(false)

  const handleOnchangeField = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
    setShowCleanFieldButton(true)
    if(event.target.value === ''){
      setShowCleanFieldButton(false)
    }
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      props.handleSearch(searchTerm);
    }
  }

  const handleCleanFieldButton = () => {
    setSearchTerm('')
    setShowCleanFieldButton(false)
  }

  const handleOnClick = () => {
    props.handleSearch(searchTerm)
  }

  return (
    <div className="search-wrapper">
      <div className="search-icon">
        <GoSearch onClick={handleOnClick}/>
        {showCleanFieldButton && <FaTimes onClick={handleCleanFieldButton} />}
      </div>
      <input
        className="search-field"
        type="text"
        value={searchTerm}
        placeholder={placeHolder}
        onChange={handleOnchangeField}
        onKeyDown={handleOnKeyDown}
      />
    </div>
  )
}

export default DefaultSearchField