import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import "./ActiveDataButton.sass";

interface ActiveDataButtonProps {
  handleOnClick: () => void;
  label: string;
}

const ActiveDataButton: React.FC<ActiveDataButtonProps> = ({
  handleOnClick,
  label,
}) => {
  return (
    <button onClick={handleOnClick} className="container-active-data-button">
      {label}
      <span>
        <IoIosCheckmarkCircleOutline />
      </span>
    </button>
  );
};

export default ActiveDataButton;