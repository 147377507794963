import React, { useState } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import './modalDisableCabin.sass';
import DisableDataButton from '../../dashboard-custom-components/components/Button/DisableDataButton/DisableDataButton';
import { BASE } from '../../basedata/constants';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

interface ModalDisableCabinProps {
    show: boolean;
    onHide: () => void;
    cabinNumber: string;
    cabinName: string;
    supplierId: string;
}

const ModalDisableCabin: React.FC<ModalDisableCabinProps> = ({ show, onHide, cabinNumber, cabinName, supplierId }) => {   

    const [loading, setLoading] = useState(false);

    const handleDisebleDataClick = () => {
        setLoading(true);  
        fetch(`${BASE.USER.url}${BASE.USER.supplier_status}/inactive?id=${supplierId}`, {
            method: 'PUT',
        })
        .then((resg) => resg.json())
        .then((response) => {
            console.log("Supplier inactivated successfully:", response);
            setLoading(false); 
            onHide(); 
            window.location.reload();
        })
        .catch((error) => {
            console.error("Error inactivating supplier:", error);
            setLoading(false);  
        });
    }

    return (
        <LargeModal show={show} onHide={onHide} title="Inativar Camarote">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <>
                <div className='container-modal-disable-cabin'>
                    <p> Você deseja inativar o camarote <span> {cabinNumber} - {cabinName} </span> ? </p>
                </div>

                <div className='container-disable-button'>
                    <div className='container-modal-disable-data-button'>
                        <DisableDataButton handleOnClick={handleDisebleDataClick} label="INATIVAR" />
                    </div>
                </div>
            </>
        </LargeModal>
    );
}

export default ModalDisableCabin;
