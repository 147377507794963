import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isAfter, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import './SearchByDate.sass';

interface SearchByDateProps {
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  startDateProp: string;
  endDateProp: string;
}

const SearchByDate: React.FC<SearchByDateProps> = ({ setStartDate, setEndDate, startDateProp, endDateProp }) => {
  const [startDate, setLocalStartDate] = useState<Date | null>(null);
  const [endDate, setLocalEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setLocalStartDate(startDateProp ? parse(startDateProp, 'yyyy-MM-dd', new Date()) : null);
    setLocalEndDate(endDateProp ? parse(endDateProp, 'yyyy-MM-dd', new Date()) : null);
  }, [startDateProp, endDateProp]);

  const handleDateChange = (
    date: Date | null,
    isStart: boolean
  ) => {
    const isValid = isStart ? (!date || !endDate || !isAfter(date, endDate)) : (!date || !startDate || !isAfter(startDate, date));

    if (isValid) {
      if (isStart) {
        setLocalStartDate(date);
        setStartDate(date ? format(date, 'yyyy-MM-dd') : '');
      } else {
        setLocalEndDate(date);
        setEndDate(date ? format(date, 'yyyy-MM-dd') : '');
      }
    } else {
      if (isStart) {
        setLocalStartDate(null);
        setStartDate('');
      } else {
        setLocalEndDate(null);
        setEndDate('');
      }
    }
  };

  const handleInputRaw = (
    e: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean
  ) => {
    const inputDate = parse(e.target.value, 'dd/MM/yyyy', new Date());

    if (!isNaN(inputDate.getTime())) {
      handleDateChange(inputDate, isStart);
    }
  };

  return (
    <div className="container-search-by-date">
      <DatePicker
        selected={startDate}
        onChange={(date) => handleDateChange(date, true)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Data de Início"
        locale={ptBR}
        className="search-field"
        maxDate={endDate}
        onChangeRaw={(e) => handleInputRaw(e, true)}
      />

      <span> - </span>

      <DatePicker
        selected={endDate}
        onChange={(date) => handleDateChange(date, false)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Data de Fim"
        locale={ptBR}
        className="search-field"
        minDate={startDate}
        onChangeRaw={(e) => handleInputRaw(e, false)}
      />
    </div>
  );
};

export default SearchByDate;
