import React, { useEffect, useState } from 'react';
import CardListCabin from '../../dashboard-custom-components/components/Card/CardListCabin/CardListCabin';
import { BASE } from '../../basedata/constants';
import { CONFIG } from '../../config/configVariables';
import ModalActiveCabin from './modalActiveCabin';
import ModalDeleteCabin from './modalDeleteCabin';
import ModalEditCabin from './modalEditCabin';
import ModalDisableCabin from './modalDisableCabin';

interface CabinRowProps {
  cabinId: string;
  name: string;
  seatsNumber: number;
  supplierId: string;
  isActive: boolean;
  location: string;
  companyId: string;
  section: string;
}

const CabinRow: React.FC<CabinRowProps> = ({ ...props }) => {
  const [coordinatorsList, setCoordinatorsList] = useState([]);
  const [showCoordinatorsLink, setShowCoordinatorsLink] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const getCoordinators = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY,
      },
      body: JSON.stringify({
        originCompanyId: props.companyId,
        supplierId: props.supplierId,
      }),
    };

    await fetch(`${BASE.USER.url}${BASE.api_user.coordinator_search}`, requestOptions)
      .then((response) => {
          return response.json();
      })
      .then((json) => {
        if (json) {
          const coordinatorsJsonList = json.coordinators;
          if (coordinatorsJsonList.length > 6) {
            setShowCoordinatorsLink(true);
            setCoordinatorsList(coordinatorsJsonList.filter((coordinator, index) => index < 5));
          } else {
            setCoordinatorsList(json.coordinators);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnClick = (type: string) => {
    if (type === 'active') {
      setModalType(props.isActive ? 'disable' : 'active');
    } else {
      setModalType(type);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getCoordinators();
  }, []);

  const initialData = {
    cabinNumber: props.cabinId,
    cabinName: props.name,
    cabinId: props.supplierId,
    seatsNumber: props.seatsNumber.toString(),
    sector: props.location,
    section: props.section,
  };

  return (
    <>
      <CardListCabin
        cabinId={props.cabinId}
        cabinName={props.name}
        seatsNumber={props.seatsNumber}
        isActive={props.isActive}
        location={props.location}
        id={props.supplierId}
        coordinators={coordinatorsList}
        showCoordinatorsLink={showCoordinatorsLink}
        handleOnClick={() => handleOnClick('coordinators')}
        handleActiveClick={() => handleOnClick('active')}
        handleEditClick={() => handleOnClick('edit')}
        handleExcludeClick={() => handleOnClick('exclude')}
      />

      {modalType === 'active' && (
        <ModalActiveCabin show={showModal} onHide={handleCloseModal} cabinNumber={props.cabinId} cabinName={props.name} supplierId={props.supplierId} />
      )}
      {modalType === 'disable' && (
        <ModalDisableCabin show={showModal} onHide={handleCloseModal} cabinNumber={props.cabinId} cabinName={props.name} supplierId={props.supplierId}/>
      )}
      {modalType === 'edit' && (
        <ModalEditCabin show={showModal} onHide={handleCloseModal} initialData={initialData} />
      )}
      {modalType === 'exclude' && (
        <ModalDeleteCabin show={showModal} onHide={handleCloseModal}  cabinNumber={props.cabinId} cabinName={props.name} supplierId={props.supplierId}/>
      )}
    </>
  );
};

export default CabinRow;


