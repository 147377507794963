const isTokenInvalid = (token: string): boolean => {
    try {
      const payloadBase64 = token.split('.')[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      
      const currentTime = Math.floor(Date.now() / 1000)  
      return decodedPayload.exp < currentTime;
    } catch (error) {
      return true;
    }
  };
  
  export default isTokenInvalid;