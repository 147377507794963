import React from "react";
import './EventStatus.sass'

import { FaCheck, FaFill, FaTimes} from 'react-icons/fa'

interface EventStatusprops {
  isActive: boolean;
}

const EventStatus: React.FC<EventStatusprops> = ({ isActive }) => {
  return (
    <div className="container-status">
      <span>
        {isActive ? (
          <div className="status">
            <span>Evento Ativo</span>
            <FaCheck className="status-icon active" />
          </div>
        ) : (
          <div className="status">
            <span>Evento Inativo</span>
            <FaTimes className="status-icon inactive"/>
          </div>
        )}
      </span>
    </div>
  );
};

export default EventStatus;
