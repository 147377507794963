import React from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import "./AddDataButton.sass";

interface AddDataButtonProps {
  handleOnClick: () => void;
  label: React.ReactNode;
  disabled?: boolean
}

const AddDataButton: React.FC<AddDataButtonProps> = ({
  handleOnClick,
  label,
  disabled
}) => {
  return (
    <button 
      onClick={handleOnClick} 
      className="container-add-data-button"
      disabled={disabled}
    >
      {label}
      <span>
        <IoIosAddCircleOutline />
      </span>
    </button>
  );
};

export default AddDataButton;