import React from 'react';
import './CardEventInfo.sass';
import EventStatus from '../../Status/EventStatus/EventStatus';

interface CardEventInfoProps {
  isActive: boolean;
  eventName: string;
  eventDescription: string;
  eventDate: string;
  eventTime: string;
  eventDayOfWeek: string;
}

const CardEventInfo: React.FC<CardEventInfoProps> = ({ 
  isActive, 
  eventName, 
  eventDescription, 
  eventDate, 
  eventTime,
  eventDayOfWeek
}) => {
  return (
    <div className='container-event-info'>
      <div className='event-box'>
        <div className='event-name-box'>
          <span className='event-name'>{eventName}</span>
        </div>
        <div className='event-description'>{eventDescription}</div>
        <div className='event-data-time-box'>
          <span className='event-data-time'>{eventDayOfWeek}</span>
          <span className='event-data-time'>{eventDate}</span>
          <span className='event-data-time'>{eventTime}</span>
          <EventStatus isActive={isActive} />
        </div>
      </div>
      <div className='line'></div>
    </div>
  );
};

export default CardEventInfo;
