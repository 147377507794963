import React from "react";
import "./ConditionalButton.sass";

interface ConditionalButtonProps {
  handleOnClick: () => void;
  label: string;
  disabled?: boolean;
}

const ConditionalButton: React.FC<ConditionalButtonProps> = ({
  handleOnClick,
  label,
  disabled = false,
}) => {
  return (
    <button 
      onClick={handleOnClick} 
      className="conditional-button-content" 
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default ConditionalButton;