import React from 'react';
import './LoadingSpinner.sass'; 

const LoadingSpinner: React.FC = () => {
  return (
    <div className="loading-spinner"></div>
  );
};

export default LoadingSpinner;
