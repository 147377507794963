import React, { useEffect, useState } from 'react'
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder'
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal'
import AddDataButton from '../../dashboard-custom-components/components/Button/AddDataButton/AddDataButton'
import './modalEventEdit.sass'
import { EventData } from '../Events/Intefaces/InterfaceEventData'
import Cookies from 'js-cookie'; 
import { BASE } from '../../basedata/constants'
import DateTimePicker from './datapiker'
import EditDataButton from '../../dashboard-custom-components/components/Button/EditDataButton/EditDataButton'
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner'

interface ModalEventEditProps {
    show: boolean
    onHide: () => void
    event: EventData | null
}

const ModalEventEdit: React.FC<ModalEventEditProps> = ({ show, onHide, event }) => {

    const [loading, setLoading] = useState(false);
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventCode, setEventCode] = useState('');

    useEffect(() => {
        if (event) {
            setEventName(event.title);
            setEventDate(event?.startDate ? new Date(event.startDate) : new Date());
            setEventCode(event.code);
        }
    }, [event]);

    const resetForm = () => {
        setEventName('');
        setEventDate(null);
        setEventCode('');
    };
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onHide()
        resetForm()
    }

    const handleAddDataClick = async () => {
        setLoading(true); 
        const companyId = Cookies.get('company'); 

        if (!companyId) {
            console.error('Company ID not found in cookies');
            return;
        }

        const requestBody = {
            eventName: eventName,
            startDateTime: eventDate,
            companyId: companyId,
            eventCode: eventCode
        };

        try {
            const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${event?.id}`, { 
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            
            const result = await response.json();

            if (response.ok) {
                setLoading(false); 
                window.location.reload(); 
            } else {
                console.error("Error updating data:", result.message || 'Unknown error');
            }
        } catch (error) {
            setLoading(false); 
            console.error("Error updating data:", error);
        } finally {
            onHide();
            resetForm();
        }
    }

    const isFormValid = () => {
        const today = new Date();
        return eventName.trim() !== '' && eventCode.trim() !== '' && eventDate && eventDate >= today;
    }

    return (
        <LargeModal show={show} onHide={() => { onHide(); resetForm(); }} title="Editar Evento">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className="container-modal-edit-event">
                <form onSubmit={handleSubmit} className="custom-form">

                    <div className="form-group form-group-fist">
                        <label className="form-label">Nome do Evento</label>
                        <input
                            type="text"
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            maxLength={20} 
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Data e hora do evento 
                        </label>
                        <DateTimePicker
                            selectedDate={eventDate}
                            onChangeDate={setEventDate}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Código do Evento</label>
                        <input
                            type="text"
                            value={eventCode}
                            onChange={(e) => setEventCode(e.target.value)}
                            maxLength={20} 
                            className="form-control"
                        />
                    </div>

                    <div className='container-button'>
                        <div className='container-modal-edit-event-button'>
                            <EditDataButton
                                handleOnClick={handleAddDataClick}
                                label="EDITAR"
                                disabled={!isFormValid()} 
                            />
                        </div>
                    </div>

                </form>
            </div>
        </LargeModal>
    )
}

export default ModalEventEdit





