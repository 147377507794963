import React, { useEffect, useState } from 'react';
import HeaderLine from '../../shared/header';
import './LastEntrancesReports.sass';
import { BASE } from '../../basedata/constants';
import CardEventInfo from '../../dashboard-custom-components/components/Card/CardEventInfo/CardEventInfo';
import { MdGraphicEq } from "react-icons/md";
import AutoUpdateButton from '../../dashboard-custom-components/components/Button/AutoUpdateButton/AutoUpdateButton';
import DefaultSearchField from '../../dashboard-custom-components/components/SearchField/DefaultSearchField';
import TableLastHits from '../../dashboard-custom-components/components/Table/TableLastHits/TableLastHits';
import Cookies from 'js-cookie';
import DefaultPagination from '../../dashboard-custom-components/components/Pagination/DefaultPagination';
import ModalNoData from '../../dashboard-custom-components/components/Modal/ModalNoData';
import iconNoData from "../../images/icon_no_data.svg";

interface User {
  name: string;
  documentType: string;
  document: string;
  dateEntry: string;
  hourEntry: string;
  supplierName: string;
  supplierNumber: string;
  isVip: boolean;
}

const LastEntrancesReports: React.FC = () => {
  const [eventData, setEventData] = useState<any>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [orderByColumn, setOrderByColumn] = useState<string>('createdAt');
  const [orderByDirection, setOrderByDirection] = useState<string>('DESC');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAutoUpdateEnabled, setIsAutoUpdateEnabled] = useState(false);

  useEffect(() => {
    fetchNextEvent();
  }, []);

  useEffect(() => {
    if (isAutoUpdateEnabled) {
      const interval = setInterval(() => {
        if (eventData) {
          fetchData(eventData.eventCode, currentPage, orderByColumn, orderByDirection);
        }
      }, 10000); 

      return () => clearInterval(interval);
    }
  }, [isAutoUpdateEnabled, eventData, currentPage, orderByColumn, orderByDirection]);

  const breadCrumbItems = [
    { status: 'inactive', label: 'Dashboard', link: '/dashboard' },
    { status: 'active', label: 'Todos os Acessos', link: '/report/LastEntrances' }
  ];

  const handleSearch = async (searchValue: string | number) => {
    const searchText = searchValue.toString(); 

    const searchFirstNameStatus = await fetchData(eventData.eventCode, currentPage, "searchFirstName", searchText);
    const searchDocumentStatus = await fetchData(eventData.eventCode, currentPage, "searchDocument", searchText);

    if (searchFirstNameStatus === 404 && searchDocumentStatus === 404) {
      setShowModal(true);
    }
  };

  const fetchNextEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_next}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do próximo evento');
        }
        return response.json();
      })
      .then(data => {
        setEventData(data.event);  
        fetchData(data.event.eventCode, currentPage, orderByColumn, orderByDirection);  
      })
      .catch(error => {
        console.error('Erro ao buscar dados do próximo evento:', error);
      });
  };

  const isActiveEvent = eventData?.status === 'active';

  const fetchData = (
    eventCode: string,
    page: number,
    searchType: "searchFirstName" | "searchDocument" | string,
    searchText: string
  ): Promise<number> => {
    const pageSize = 10;
    const profile = Cookies.get('profile');
    const supplierId = Cookies.get('companyId');

    let requestBody: any = {
      eventId: eventCode,
      pageSize: pageSize,
      page: page,
    };

    if (searchType === "searchFirstName" || searchType === "searchDocument") {
      requestBody[searchType] = searchText;
    } else {
      requestBody.orderByColumn = orderByColumn;
      requestBody.orderByDirection = orderByDirection;
    }

    if (profile !== 'administrador' && supplierId) {
      requestBody = {
        ...requestBody,
        supplierId: supplierId,
      };
    }

    return fetch(`${BASE.EVENT.url}${BASE.EVENT.last_accesses}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          return response.status;
        }
        return response.json().then(data => {
          const mappedUsers = mapDataToUsers(data.data);
          setUsers(mappedUsers);
          setCurrentPage(data.page);
          setTotalPages(Math.ceil(data.total / data.pageSize));
          setTotalRecords(data.total);
          return 200;
        });
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        return 500; 
      });
  };

  const mapDataToUsers = (data: any[]): User[] => {
    return data.map((item) => ({
      name: `${item.firstName} ${item.lastName}`,
      documentType: item.documentType || 'Documento',
      document: formatDocument(item.document, item.documentType),
      dateEntry: item.createdAt ? formatDate(new Date(item.createdAt)) : '',
      hourEntry: item.createdAt ? formatTime(new Date(item.createdAt)) : '',
      supplierName: item.supplierName || '',
      supplierNumber: `Camarote ${item.supplierNumber || ''}`,
      isVip: item.isVip,
    }));
  };

  const formatTime = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDocument = (document: string | undefined, documentType: string | undefined): string => {
    if (documentType === 'CPF' && document) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return document || '';
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (eventData) {
      fetchData(eventData.eventCode, page, "orderByColumn", orderByColumn);
      fetchData(eventData.eventCode, page, "orderByDirection", orderByDirection);
    }
  };

  const handleSort = (column: string) => {
    const direction = orderByColumn === column && orderByDirection === 'ASC' ? 'DESC' : 'ASC';
    setOrderByColumn(column);
    setOrderByDirection(direction);
    if (eventData) {
      fetchData(eventData.eventCode, currentPage, column, direction);
    }
  };

  return (
    <div className='container-last-entrances-reports'>
      <HeaderLine currentPage={"Camarotes"} breadIttems={breadCrumbItems} />
      
      {eventData ? (
        
        <>
      
        <CardEventInfo
          isActive={eventData.isActive}
          eventName={eventData.title || ''}
          eventDescription={eventData.eventDescription || ''}
          eventDate={eventData.startDateTime ? new Date(eventData.startDateTime).toLocaleDateString('pt-BR') : ''}
          eventTime={eventData.startDateTime ? formatTime(new Date(eventData.startDateTime)) : ''}
          eventDayOfWeek={eventData.startDateTime ? capitalizeFirstLetter(new Date(eventData.startDateTime).toLocaleDateString('pt-BR', { weekday: 'long' })) : ''}
        />

      <div className='report-section'>
        <h1>Todos os Acessos</h1>
        {/* <button className='report-button'>
          Relatório
          <MdGraphicEq className='icon' />
        </button> */}
      </div>
      <div className="auto-update-section">
         <AutoUpdateButton onToggle={setIsAutoUpdateEnabled} isEnabled={false} />
        <div className="search-field-box">
          <DefaultSearchField
            placeHolder="Pesquisar por nome ou documento na lista abaixo"
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className="table-last-hits-section">
        <TableLastHits data={users} onSortChange={handleSort} />
      </div>

      <div className="pagination-section">
        <DefaultPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalRecords={totalRecords}
        />
      </div>
      </>
      ) : (
        <div className='no-data-container'>
          <div className="icon-container">
            <img src={iconNoData} alt="Icon" />
          </div>
          <div className='text-container'>
            <p>Não há dados a serem exibidos.</p>
            <p>
              <a href="/dashboard" className="dashboard-link">Clique aqui</a> 
              para acessar o dashboard
            </p>
          </div>
        </div>
      )}

      <div className='modal-box'>
        <ModalNoData 
          show={showModal} 
          onHide={() => setShowModal(false)} 
          title="Pesquisa sem resultados">
          <p>Nenhum resultado corresponde a sua pesquisa.</p>
        </ModalNoData>
      </div>
    </div>
  );
};

export default LastEntrancesReports;









