import React from 'react';
import { IoStar } from 'react-icons/io5';
import './VIPStatus.sass';

const VIPStatus: React.FC = () => {
  return (
    <div className='status-vip'>
      <span className='vip-letters'>VIP</span>
      <span className='status-vip-icon'><IoStar /></span>
    </div>
  );
}

export default VIPStatus;
