import React, { useState } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import './modalEventDisable.sass'; 
import DisableDataButton from '../../dashboard-custom-components/components/Button/DisableDataButton/DisableDataButton';
import { EventData } from '../Events/Intefaces/InterfaceEventData';
import { BASE } from '../../basedata/constants';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

interface ModalEventDisableProps {
    show: boolean;
    onHide: () => void;
    event: EventData | null;
}

const ModalEventDisable: React.FC<ModalEventDisableProps> = ({ show, onHide, event }) => {   

    const [loading, setLoading] = useState(false);

    const handleDisableEventClick = async () => {
        
        setLoading(true); 
        if (!event) return;

        const newStatus = !event.active;
        const requestBody = {
            status: false
        };
    
        try {
            const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${event.id}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });
            
            const result = await response.json();
    
            if (response.ok) {
                setLoading(false);
                window.location.reload();
            } else {
                console.error("Error updating event status:", result.message || 'Unknown error');
            }
            // alert(`O evento ${event.code} - ${event.title} foi ${newStatus ? 'ativado' : 'desativado'}.`);
        } catch (error) {
            setLoading(false);
            // alert("Error updating event");
        } finally {
            onHide();
        }
    };
    

    return (
        <LargeModal show={show} onHide={onHide} title="Inativar Evento">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className='container-modal-disable-event'>
                <p> Você deseja inativar o evento <span> {event ? `${event.code} - ${event.title}` : "Evento não encontrado"} </span> ? </p>
            </div>

            <div className='container-disable-button'>
                <div className='container-modal-disable-data-button'>
                    <DisableDataButton handleOnClick={handleDisableEventClick} label="INATIVAR" />
                </div>
            </div>
        </LargeModal>
    );
}

export default ModalEventDisable;

