import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useProfile = () => {
  const [profile, setProfile] = useState<string | undefined>(undefined);

  const fetchAndSetProfile = () => {
    const profileData = Cookies.get('profile');
    if (profileData) {
      setProfile(profileData);
    } else {
      setProfile(undefined);
    }
  };

  const isCorrectProfile = (requiredProfile: string): boolean => {
    return profile === requiredProfile;
  };

  useEffect(() => {
    fetchAndSetProfile();
  }, []);

  return { profile, isCorrectProfile };
};

export default useProfile;
