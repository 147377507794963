import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
} from 'react-bootstrap'
import './Events.sass'
import HeaderLine from '../../shared/header'
import { IoMdAddCircleOutline } from 'react-icons/io'
import IconButton from '../../dashboard-custom-components/components/Button/IconButton/IconButton'
import { BASE } from '../../basedata/constants'
import Cookies from 'js-cookie'
import { CONFIG } from '../../config/configVariables'
import { MdOutlineStadium } from 'react-icons/md'
import DefaultPagination from '../../dashboard-custom-components/components/Pagination/DefaultPagination'
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner'
import TableEventsList from '../../dashboard-custom-components/components/Table/TableEvents/TableEventsList'
import ModalCreateEvent from '../components/modalEventCreate'
import { EventData } from './Intefaces/InterfaceEventData'

function Events() {
  const companyId = Cookies.get('company')

  const pageSize = 5
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [search, setSearch] = useState('')

  const [showNoDataModal, setShowNoDataModal] = useState(false)
  const [showDefaultModal, setShowDefaultModal] = useState(false) 

  const [hasEvent, setHasEvent] = useState(false)

  const [events, setEvents] = useState<EventData[]>([
    {
      code: "",
      title: "",
      startDate: new Date(),
      active: false,
      id: null
    }
  ])
  const [loading, setLoading] = useState(false) 

  const breadCrumbItens = [
    {
      status: 'active',
      label: 'Eventos',
      link: '#'
    }
  ]


  const buttonArgs = {
    label: 'Incluir Eventos',
    icon: <IoMdAddCircleOutline size={20} />
  }

  const fetchEvents = (searchTerm = '', page = 1) => {
    setLoading(true); 

    const requestOptions = {
      method: searchTerm ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: searchTerm ? JSON.stringify({ searchTerm, companyId }) : null
    };
    
    const url = `${BASE.EVENT.url}${BASE.EVENT.all_events}?page=${page}&limit=${pageSize}`;
  
    fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Erro na requisição: ${response.status}`);
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
  
          calculateAndSetTotaPages(json)
          setEvents(json.events.map(event => ({
            code: event.eventCode,
            title: event.eventName,
            startDate: new Date(event.startDateTime),
            active: event.isActive,
            id: event.id
          })));

          setHasEvent(true);
        }
      })
      .catch((error) => setHasEvent(false))
      .finally(() => setLoading(false)); 
  };

  const calculateAndSetTotaPages = (json: any) => {
    const totalData = Number(json.total)
    setTotal(totalData)

    const totalOfPages = totalData / pageSize

    if (totalData % pageSize) {
      setTotalPages(Math.floor(totalOfPages + 1))
    } else {
      setTotalPages(totalOfPages)
    }
  }

  const handleAddEvent = () => {
    setShowDefaultModal(true); 
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    fetchEvents(search, page);
  }, [search, page]);

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <div className="page-container">
      <HeaderLine currentPage={'Eventos'} breadIttems={breadCrumbItens} />

      {loading ? (
        <div className="loading-container">
          <LoadingSpinner />
        </div>
      ) : hasEvent ? (
        <>
          <Row className="event-actions">
            <Col xs={10}>
              <span>Lista de Eventos</span>
            </Col>

            <Col xs={2}>
              <IconButton handleOnClick={handleAddEvent} {...buttonArgs} />
            </Col>

          </Row>

          <Col></Col>
          <Col>
            <Row>
              <TableEventsList data={events}/>
            </Row>
            <Row>
              <DefaultPagination
                currentPage={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                totalRecords={total}
              />
            </Row>
          </Col>
        </>
      ) : (
        <div className="parent-container">
          <div className="background">
            <MdOutlineStadium className="icon" />
          </div>
          <div className='dashboard-text-box'>
            <p className="dashboard-text">Vamos começar!</p>
            <p className="dashboard-text-office">
              <a href="#" className="gradient-text" onClick={handleAddEvent}>
                Clique aqui
              </a>{' '}
              para cadastrar seu primeiro evento.
            </p>
          </div>
        </div>
      )}
      <ModalCreateEvent
            show={showDefaultModal}
            onHide={() => setShowDefaultModal(false)} 
          />
      </div>      
    </>
  )
}

export default Events
