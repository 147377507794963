import React, { ChangeEvent } from 'react';
import './SearchWithoutIcon.sass';

interface SearchWithoutIconProps {
  placeHolder: string;
  setSearchTerm: (term: string) => void;
}

const SearchWithoutIcon: React.FC<SearchWithoutIconProps> = ({ placeHolder, setSearchTerm }) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <input
      className="input-search-without-icon"
      type="text"
      placeholder={placeHolder}
      onChange={handleOnChange}
    />
  );
};

export default SearchWithoutIcon;
