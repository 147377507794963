import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./DropdownSelect.sass";

interface DropdownSelectProps<T> {
  items: T[];
  selectedId: string | null;
  onSelect: (selectedId: string) => void;
  labelKey: keyof T;
  subLabelKey?: keyof T;
  className?: string;
}

const DropdownSelect = <T extends { id: string }>({
  items,
  selectedId,
  onSelect,
  labelKey,
  subLabelKey,
  className,
}: DropdownSelectProps<T>) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const selectedItem = items.find((item) => item.id === selectedId);

  return (
    <div className="dropdown-card-container">
      <div className="dropdown-content">
        <div className="dropdown-header full-width">

          <div className={`${className || ''} dropdown-selection-area width-100`}> {/* Aplicando classe opcional */}
            <div
              onClick={toggleDropdown}
              className={`dropdown-header ${showDropdown ? "active" : ""}`}
            >
              {selectedId ? (
                <span>
                  {subLabelKey && selectedItem?.[subLabelKey]
                    ? `${String(selectedItem?.[subLabelKey])} - ${String(selectedItem?.[labelKey])}`
                    : String(selectedItem?.[labelKey])}
                </span>
              ) : (
                <div>
                  <span>Selecione</span>
                  <span>
                    <MdKeyboardArrowDown />
                  </span>
                </div>
              )}
            </div>
            {showDropdown && (
              <ul className="dropdown-items-list show">
                {items.map((item) => (
                  <li
                    key={item.id}
                    className={item.id === selectedId ? "selected" : ""}
                    onClick={() => {
                      onSelect(item.id);
                      setShowDropdown(false);
                    }}
                  >
                    {subLabelKey && item[subLabelKey]
                      ? `${String(item[subLabelKey])} - ${String(item[labelKey])}`
                      : String(item[labelKey])}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownSelect;
