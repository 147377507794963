import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import isTokenInvalid from './jwtAuth';

export default function useAuth() {

    const [authenticated, setAutenticated] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('token')
        if (token) {
            setAutenticated(true)
        }
        setLoading(false)
    }, [])

    async function handleLogin() {
        setAutenticated(true)
    }

    async function handleLogout() {
        const cookies = Cookies.get()
        for (const cookie in cookies) {
            Cookies.remove(cookie, {path: '/'});
        }

        setAutenticated(false)
        navigate('/')
    }

    return { authenticated, loading, handleLogin, handleLogout }
}