import React from 'react';
import './CardRecentEventAccess.sass';
import { MdArrowForwardIos } from "react-icons/md";
import VIPStatus from '../../Status/VIPStatus/VIPStatus';
import LoadingButton from '../../Button/Loading/Loading';
import { format } from 'date-fns';

export interface User {
  user_name: string;
  user_document_type: string;
  user_document: string;
  user_data_entry: string;
  user_hour_entry: string;
  supplier_number: string;
  supplier_name: string;
  isVip: boolean;
}

export interface CardRecentEventAccessProps {  
  users: User[];
  to: string; 
}

const documentTypeMap: { [key: string]: string } = {
  "CPF": "CPF",
  "passport": "Passaporte"
};

const CardRecentEventAccess: React.FC<CardRecentEventAccessProps> = ({ users, to }) => {
  return (
    <div className='card-body'>
      <div className="button-container">
        <div className='container-left-button'>
          
          <div className="left-text">
            Últimos acessos
            {users.length > 0 && <span className="arrow-icon"><MdArrowForwardIos /></span>}
          </div>

          {users.length > 0 && ( 
            <a href={to} className="left-button">
              ver mais
            </a>
          )}
        </div>
      </div>

      {users.length === 0 && (
        <p className="no-accesses-message">Não há registros de últimos acessos.</p>
      )}

      {users.length > 0 && (
        <table>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td className='name-cell'>
                  {user.user_name} <br />
                  {documentTypeMap[user.user_document_type] || user.user_document_type} {user.user_document}
                </td>
                <td className='data-cell'>
                  {user.user_data_entry} <br />
                  {user.user_hour_entry}
                </td>
                <td className='loge-cell'>
                  {user.supplier_number} <br/>
                  {user.supplier_name}
                </td>
                <td className='vip-cell'>{user.isVip && <VIPStatus />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default CardRecentEventAccess;
