import React from 'react';
import './BreadCrumb.sass';
import { GoChevronRight } from "react-icons/go";

interface NavItem {
  status: string;
  label: string;
  link: string;
}

export interface BreadCrumbProps {
  navItems?: NavItem[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ navItems = [] }) => {
  return (
    <ul className="breadcrumb-wrapper">
      {navItems.map((item, index) => (
        <li key={index} className={`${item.status}`}>
          <i><GoChevronRight/></i>
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default BreadCrumb;
