import React, { useState } from 'react';
import { RiExpandUpDownLine } from "react-icons/ri";
import './TableLastHits.sass';
import VIPStatus from '../../Status/VIPStatus/VIPStatus';

interface UserData {
  name: string;
  documentType: string;
  document: string;
  dateEntry: string;
  hourEntry: string;
  supplierName: string;
  supplierNumber: string
  isVip: boolean;
}

const TableLastHits: React.FC<{ data: UserData[], onSortChange: (orderByColumn: string, orderByDirection: string) => void }> = ({ data, onSortChange }) => {
  const [orderByColumn, setOrderByColumn] = useState<string>('createdAt');
  const [orderByDirection, setOrderByDirection] = useState<string>('DESC');

  const handleSort = (column: string) => {
    const newDirection = orderByColumn === column && orderByDirection === 'ASC' ? 'DESC' : 'ASC';
    setOrderByColumn(column);
    setOrderByDirection(newDirection);
    onSortChange(column, newDirection);
  };

  return (
    <div className='table-last-hits'>
      <table className="table-last-hits">
        <thead>
          <tr>
            <th className="table-col col1">
              <div className="header-content">
                <span className="text">Nome e Documento</span>
                <button className="icon" onClick={() => handleSort('firstName')}>
                  <RiExpandUpDownLine />
                </button>
              </div>
            </th>
            <th className="table-col col2">
              <div className="header-content">
                <span className="text">Data e Hora</span>
                <button className="icon" onClick={() => handleSort('createdAt')}>
                  <RiExpandUpDownLine />
                </button>
              </div>
            </th>
            <th className="table-col col3">
              <div className="header-content">
                <span className="text">Camarote</span>
                <button className="icon" onClick={() => handleSort('supplierName')}>
                  <RiExpandUpDownLine />
                </button>
              </div>
            </th>
            <th className="table-col col4">
              <div className="header-content">
                <span className="text">VIP</span>
                <button className="icon" onClick={() => handleSort('isVip')}>
                  <RiExpandUpDownLine />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr key={index}>
              <td className="table-col col1">
                <div>{user.name}</div>
                <div>{user.documentType}: {user.document}</div>
              </td>
              <td className="table-col col2">
                <div>{user.dateEntry}</div>
                <div>{user.hourEntry}</div>
              </td>
              <td className="table-col col3">
                {user.supplierNumber} <br/>
                {user.supplierName} 
              </td>
              <td className="table-col col4">{user.isVip && <VIPStatus />}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableLastHits;





