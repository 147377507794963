import React from "react";
import { SlClose } from "react-icons/sl";
import "./DisableDataButton.sass";

interface DisableDataButtonProps {
  handleOnClick: () => void;
  label: string;
}

const DisableDataButton: React.FC<DisableDataButtonProps> = ({
  handleOnClick,
  label,
}) => {
  return (
    <button onClick={handleOnClick} className="container-disable-data-button">
      {label}
      <span>
        <SlClose />
      </span>
    </button>
  );
};

export default DisableDataButton;