import React, { useState } from 'react'
import './CardListCabin.sass'
import IconButton from '../../Button/IconButton/IconButton'
import { GrLocation } from 'react-icons/gr'
import { LiaEdit } from 'react-icons/lia'
import { FaRegTrashAlt, FaRegTimesCircle, FaRegCheckCircle } from 'react-icons/fa'
import { Col, Row } from 'react-bootstrap'
import ModalCreateCabinManagers from '../../../../views/components/modalCreateCabinManagers'

interface Coordinator {
  firstName: string
  lastName: string
  id: string
}

interface CardListCabinProps {
  cabinId: string
  cabinName: string
  seatsNumber: number
  id: string
  isActive: boolean
  location: string
  showCoordinatorsLink: boolean
  coordinators: Coordinator[]
  handleActiveClick: () => void
  handleEditClick: () => void
  handleExcludeClick: () => void
  handleOnClick?: () => void 
}

const buttonArgsEdit = {
  label: 'Editar',
  icon: <LiaEdit />
}

const buttonArgsExclude = {
  label: 'Excluir',
  icon: <FaRegTrashAlt />
}

const CardListCabin: React.FC<CardListCabinProps> = ({
  cabinId,
  cabinName,
  seatsNumber,
  coordinators,
  isActive,
  location,
  showCoordinatorsLink,
  handleActiveClick,
  handleEditClick,
  handleExcludeClick,
  id 
}) => {
  const [showModal, setShowModal] = useState(false);

  const buttonArgsActive = {
    label: `${isActive ? 'Inativar' : 'Ativar'}`,
    icon: isActive ? <FaRegTimesCircle /> : <FaRegCheckCircle />
  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Row className="card-list-cabin">
        <Col className="cabin-box-main" xs={10}>
          <Row className="cabin-box-wrapper">
            <Col className="cabin-info" xs={2}>
              <span className="cabin-label">Camarote</span>
              <span className="cabin-id">{cabinId}</span>
              <span className="cabin-name">{cabinName}</span>
            </Col>
            <Col className="cabin-detail" xs={2}>
              <div>
                <div className="cabin-label">Assentos</div>
                <div className="cabin-id">{seatsNumber}</div>
              </div>
              <div className="item-active">
                <div className={`detail-active`}>
                  {isActive ? (
                    <div className='active status'>
                      <span>Ativo</span>
                      <FaRegCheckCircle />
                    </div>
                  ) : (
                    <div className='inactive status'>
                      <span>Inativo</span>
                      <FaRegTimesCircle />
                    </div>
                  )}
                </div>
              </div>
            </Col>

            <Col className="cabin-managers" xs={6}>
              <span className="coordinators-custom-color">
                Gestores do Camarote:
              </span>
              <ul className="cabin-managers-list">
                {coordinators.length > 0 ? (
                  coordinators.map((coordinator) => (
                    <li key={coordinator.id}>
                      <span>
                        {coordinator.firstName} {coordinator.lastName}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>
                    <a className="custom-link" onClick={handleShowModal}>
                      Clique aqui
                    </a>{' '}
                    para cadastrar um gestor
                  </li>
                )}
                {showCoordinatorsLink && (
                  <li>
                    <a href="#" className="custom-link" onClick={handleShowModal}>
                      + Gestores
                    </a>
                  </li>
                )}
              </ul>
              <Row>
                <div className="cabin-location">
                  {location}
                  <GrLocation />
                </div>
              </Row>
            </Col>
            <Col xs={2}>
              <IconButton
                label="Gestores"
                handleOnClick={handleShowModal}
                icon={<LiaEdit />}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={2} className="box-button-list">
          <div className="edit-buttons">
            <IconButton
              handleOnClick={handleActiveClick}
              {...buttonArgsActive}
            />
            <IconButton
              handleOnClick={handleEditClick}
              {...buttonArgsEdit}
            />
          </div>
          <IconButton
            handleOnClick={handleExcludeClick}
            {...buttonArgsExclude}
          />
        </Col>
      </Row>

      <ModalCreateCabinManagers
        show={showModal}
        onHide={handleCloseModal}
        id={id} 
        cabinId={cabinId}
        cabinName={cabinName}
      />

    </>
  )
}

export default CardListCabin



