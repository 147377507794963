import React, { useState } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import ActiveDataButton from '../../dashboard-custom-components/components/Button/ActiveDataButton/ActiveDataButton';
import './modalEventActive.sass';
import { BASE } from '../../basedata/constants';
import { EventData } from '../Events/Intefaces/InterfaceEventData';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

interface ModalEventActiveProps {
    show: boolean;
    onHide: () => void;
    event: EventData | null;
}

const ModalEventActive: React.FC<ModalEventActiveProps> = ({ show, onHide, event }) => {
    
    const [loading, setLoading] = useState(false);

    const handleActiveDataClick = async () => {

        setLoading(true); 

        if (!event) return;
        const requestBody = {
            status: true
        };
    
        try {
            const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${event.id}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });
            
            const result = await response.json();
    
            if (response.ok) {
                setLoading(false);
                window.location.reload();
            } else {
                console.error("Error updating event status:", result.message || 'Unknown error');
            }
            // alert(`O evento ${event.code} - ${event.title} foi ativado.`);
        } catch (error) {
            setLoading(false);
            // alert("Error updating event");
        } finally {
            onHide();
        }
    };
    
    return (
        <LargeModal show={show} onHide={onHide} title="Ativar Evento">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className='container-modal-active-event'>
                <p> Você deseja ativar o evento <span> {event?.code} - {event?.title} </span> ? </p>
            </div>

            <div className='container-active-button'>
                <div className='container-modal-active-data-button'>
                    <ActiveDataButton handleOnClick={handleActiveDataClick} label="ATIVAR" />
                </div>
            </div>
        </LargeModal>
    );
}

export default ModalEventActive;
