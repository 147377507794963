import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { TbMail } from "react-icons/tb";
import { LuClock3 } from "react-icons/lu";
import { MdOutlineStadium } from "react-icons/md";
import CardNumbersLargeMissing from '../../dashboard-custom-components/components/Card/CardNumbersLarge/CardNumbersMissing';
import LastAccess from '../components/lastAccess';
import DefaultButton from '../../dashboard-custom-components/components/Button/DefaultButton/DefaultButton'
import CardNumbersSmall from '../../dashboard-custom-components/components/Card/CardNumbersSmall/CardNumbersSmall'
import CardNumbersLarge from '../../dashboard-custom-components/components/Card/CardNumbersLarge/CardNumbersLarge'
import HeaderLine from '../../shared/header'
import './Dashboard.sass';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import AutoUpdateButton from '../../dashboard-custom-components/components/Button/AutoUpdateButton/AutoUpdateButton';
import { BASE } from '../../basedata/constants';
import EventActiveCard from '../components/EventActiveCard';
import ModalSelectEvent from '../components/modalSelectEvent';
import ModalCreateEvent from '../components/modalEventCreate';
import useProfile from '../../../src/Context/Profile';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface EventData {
  teamVisiting: string;
  teamHome: string;
  eventTime: string;
  eventDate: string;
  eventDay: string;
  eventDescription: string;
  isActive: boolean;
  eventName: string;
  eventType: string;
  externalId: string | null;
  id: string;
  locationId: string;
}

const Dashboard: React.FC = () => {

  const [totalPeople, setTotalPeople] = useState(0);
  const [peakHourFive, setPeakHourFive] = useState<string | null>(null);
  const [percentagePresent, setPercentagePresent] = useState<number | null>(null);
  const [percentageMissing, setPercentageMissing] = useState<number | null>(null);
  const [missingPeople, setMissingPeople] = useState<number | null>(null);
  const [dataGraphic, setDataGraphic] = useState<any>({});
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [eventData, setEventData] = useState<EventData | null>(null); 
  const [eventId, setEventId] = useState<string | null>(null); 
  const [showEventModal, setShowEventModal] = useState(false) 

  const [showModal, setShowModal] = useState(false);

  const buttonData = useMemo(() => ({
    handleOnClick: () => handleShowModal(),
    label: 'Ver outros eventos'
  }), []);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const demoCardSmall = useMemo(() => ({
    label: 'Convidados',
    total: '0',
    icon: <TbMail />
  }), []);
  

  const fetchEventData = async (eventId) => {
    try {
      const response = await fetch(`${BASE.EVENT.url}/event/${eventId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao buscar dados do evento');
      }
  
      const data = await response.json();
      setEventData(data.event);
      fetchData(data.event.eventCode);
    } catch (error) {
      console.error('Erro ao buscar dados do evento:', error);
    }
  };
  
  useEffect(() => {
    if (eventId) {
      fetchEventData(eventId);
    }
  }, [eventId]);

  const demoCardSmallHour = useMemo(() => ({
    label: 'Horário de Pico',
    total: `${peakHourFive || '0'}`,
    icon: <LuClock3 />
  }), [peakHourFive]);


  const demoCardLarge = useMemo(() => ({
    label: 'Presentes',
    total: `${totalPeople}`,
    percent: parseFloat(percentagePresent?.toFixed(2) || '0'),
  }), [totalPeople, percentagePresent]);


  const demoCardLargeMissing = useMemo(() => ({
    label: 'Faltantes',
    total: `${missingPeople || '0'}`,
    percent: parseFloat(percentageMissing?.toFixed(2) || '0'),
  }), [missingPeople, percentageMissing]);

  const fetchData = async (url: string) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`${response.status}`);
    }
    return response.json();
  };

  const { profile, isCorrectProfile } = useProfile();

  const breadCrumbItems = useMemo(() => [{
    status: 'active',
    label: 'Dashboard',
    link: '#'
  }], []);

  const [isAutoUpdateEnabled, setIsAutoUpdateEnabled] = useState(() => {
    const savedState = sessionStorage.getItem('isAutoUpdateEnabled');
    return savedState ? JSON.parse(savedState) : true;
  });

  const handleToggle = useCallback((isEnabled: boolean) => {
    setIsAutoUpdateEnabled(isEnabled);
    sessionStorage.setItem('isAutoUpdateEnabled', JSON.stringify(isEnabled));
  }, []);


  const fetchNextEvent = () => {
    setLoading(true) 
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_next}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao buscar dados do próximo evento');
        }
        return response.json();
      })
      .then((data) => {
        setEventData(data.event);
        fetchData(data.event.eventCode);
        setLoading(false); 
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do próximo evento:', error);
        setLoading(false) 
      });
  };

  useEffect(() => {
    fetchNextEvent();
  }, []);
  
  return (
    <div className='dashboard-container'>
      <HeaderLine currentPage={"Camarotes"} breadIttems={breadCrumbItems} />

      {loading ? ( 
          <div className='loading-dashboard-container'>
            <LoadingSpinner /> 
          </div>
        ) : (
        eventData ? (
          <>
            <div>
              <EventActiveCard event={eventData} />
            </div>

            <div className="button-see-events spacing-default-button ">
              <DefaultButton {...buttonData} />
            </div>

            <Row className="row-see-events no-gutters">
              <Col xs={3} className="mt-20">
                <CardNumbersSmall {...demoCardSmall} />
              </Col>
              <Col xs={9} className="mt-20 pe-0">
                <CardNumbersLarge {...demoCardLarge} />
              </Col>
            </Row>

            <Row className="row-see-events no-gutters">
              <Col xs={3} className="mt-20">
                <CardNumbersSmall {...demoCardSmallHour} />
              </Col>
              <Col xs={9} className="mt-20 pe-0">
                <CardNumbersLargeMissing {...demoCardLargeMissing} />
              </Col>
            </Row>

            <ModalSelectEvent show={showModal} onHide={handleHideModal} setEventId={setEventId} />

            <div className="align-end-auto-update-button">
              <AutoUpdateButton onToggle={handleToggle} isEnabled={true} />
            </div>

            <div style={{ marginTop: '10px' }}>
              <LastAccess />
            </div>
          </>
        ) : (
          <div className="parent-container">
            <div className="background">
              <MdOutlineStadium className="icon" />
            </div>

            <div className='dashboard-text-box'>
              <p className="dashboard-text">Bem vindo!</p>
              {isCorrectProfile('Administrador') && (
                <p className="dashboard-text-office">
                  <a href="#" className="gradient-text" onClick={() => setShowEventModal(true)}>
                    Clique aqui 
                  </a>
                  {' '} para cadastrar seu primeiro evento.
                </p>
              )}
            </div>
            <ModalCreateEvent show={showEventModal} onHide={() => setShowEventModal(false)} />
          </div>
        )
      )}
    </div>
  ); 
};
  
export default Dashboard;