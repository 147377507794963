import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import faceOK from '../../images/icon-smile.svg'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreateAdmin } from '../components/userCreateAdmin'
import {AiTwotoneDelete, AiOutlineClose} from "react-icons/ai";

function CreateAdminUser() {
    const currentUser = sessionStorage.getItem('role')
    const companyId = sessionStorage.getItem('company')

    const [isLoading, setLoading] = useState(false)

    const [show, setShow] = useState(false);

    if (currentUser === 'default') {
        window.location.href = '/dashboard'
    }
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }
    const [photoState, setphotoState] = useState<string>('')

    const handleResponse = useCallback((message: string) => {
        setphotoState(message)
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        if (params.get('fixBrowser')) {
            setphotoState('mobile')
        }
    }, [])

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const handleDeleteUser = (userId) => {
      deleteUser(userId)
    }

  const getAllUsers = () => {
    setLoading(true);
    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.listAll}/${companyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //Authorization: CONFIG.API_KEY
      }
    })
    .then((res) => res.json())
    .then((users) => {
      const userRequests = users.filter((user) => user.firebaseId != null)
      .map(user => (
        fetch(`${BASE.COMPANY.url}${BASE.COMPANY.get_user_by_userId}/${user.firebaseId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }).then(res => res.json())
      ));
    Promise.all(userRequests)
    .then(userDetails => {
      const userList = userDetails.map(user => ({
        name: user.displayName,
        email: user.email,
        idUser: user.uid
      }));
      setLoading(false);
      setPridedUserList(userList);
    })
    .catch(error => {
      setLoading(false);
      console.error("Error fetching user details:", error);
    });
  })
  .catch((error) => {
    setLoading(false);
    console.error("Error fetching users:", error);
    });
  };


  const deleteUser = (userId) => {

    if (confirm("Confirmar exclusão?") == false) {
      return false
    }

    setLoading(true)

    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.delete_firebase_user}/${userId}`, {
      method: 'DELETE',
      headers: {
        //Authorization: CONFIG.API_KEY
      }
    })
    .then((response) => {
      if (response.ok) {
        return fetch(`${BASE.COMPANY.url}${BASE.COMPANY.delete_user}/${userId}`, {
          method: 'DELETE',
          headers: {
            //Authorization: CONFIG.API_KEY
          }
        });
      } else {
        throw new Error('Failed to delete user in Firebase');
      }
    })
    .then((response) => {
      if (response.ok) {
        alert('Usuário excluído com sucesso');
        window.location.reload();
      } else {
        throw new Error('Failed to delete user from database');
      }
    })
    .catch((error) => {
      console.error('Error deleting user:', error);
      setLoading(false);
    });
  };

    useEffect(() => {
        getAllUsers()
    }, [])


    return (
      <>
      <h2 className='table-title'>Cadastro de acessos</h2>
        <Row>
          <Col xs={12} lg={9}>
            <Table responsive hover>
              <thead className="table-people-header">
                <tr>
                  <th scope="col">
                    Dados
                  </th>
                  <th scope="col">
                    E-mail
                  </th>
                  <th scope="col" className='text-center'>
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className='table-people-data'>
              {pridedUserList && pridedUserList.map((user: any, index) => (
              user.recoveryKey === 'admin' ? (<></>) : (
                <tr key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
                  <td scope="row" className="table-people-info">
                    <div className="user-info">
                      <img src={faceOK} alt={user.name} className="user-avatar" />
                      <div>
                        <h3>{user.name}</h3>
                      </div>
                    </div>
                  </td>
                    <td>
                      <h6 className='text-dark'>{user.email}</h6>
                    </td>
                    <td>
                      <ul className="group-action">
                        <li>
                        <span className="sidebar-icon icon-red" onClick={() => handleDeleteUser(user.idUser)}>
                          <AiTwotoneDelete />
                        </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  )
                ))}
              </tbody>
            </Table>
          </Col>

          <Col xs={12} lg={3}>
            <UserCreateAdmin />
          </Col>
        </Row>
      </>
    )
}

export default CreateAdminUser
