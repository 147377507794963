import React, { useCallback, useEffect, useState, useContext } from 'react'

import './App.sass'
import './index.scss'

import Monitoring from './views/Monitoring/Monitoring'

import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import VisitorsList from './views/VisitorList/Visitors'
import CreateUser from './views/Auth/CreateUser'
import EditUser from './views/Auth/EditUser'
import Approvers from './Approvers/Approvers'
import GameReports from './views/Reports/GameReports'
import Reports from './views/Reports/Reports'

import { AuthProvider } from './Context/AuthContext'

import { Context } from './Context/AuthContext'

import PropTypes from 'prop-types';
import Organization from './views/Organizations/Organizations'
import Providers from './views/Providers/Providers'
import DashProvider from './views/Dashboard/DashboardProvider'
import DashCoordinator from './views/Dashboard/DashboardCoordinator'
import DashLocal from './views/Dashboard/DashboardLocal'
import Dashboard from './views/Dashboard/Dashboard'
import CreateAdminUser from './views/Auth/CreateAdminUser'
import DashboardFanBase from './views/Dashboard/DashboardFanbase'
import HeaderLine from './shared/header'
import BlockUserlist from './views/Auth/BlockUserlist'
import Login from './views/Auth/Login'
import Sidebar from './dashboard-custom-components/components/Sidebar/Sidebar'
import Cookies from 'js-cookie'

import { LuLayoutDashboard } from 'react-icons/lu';
import { TbCalendarDue } from 'react-icons/tb';
import { IoLogOutOutline } from "react-icons/io5";
import { TbArmchair } from "react-icons/tb";
import { Col, Row } from 'react-bootstrap'
import Cabins from './views/Cabins/Cabins'
import Events from './views/Events/Events'
import LastEntrancesReports from './views/Reports/LastEntrancesReports'
import UsersCallup from './views/UsersCallup/UsersCallup'

CustomRoutes.propTypes = {
  isPrivate: PropTypes.bool
};

function CustomRoutes({ isPrivate, ...rest }) {
  // const { authenticated } = useContext(Context)
  // if (isPrivate && !authenticated) {
  //   return <> {rest} </>
  // }

  return <> {rest} </>
}


const actualRole = Cookies.get('role') ? JSON.parse(Cookies.get('role')): ''


function App() {
  const [profile, setProfile] = useState<string | null>();
  
  const handleLogout = () => {
    const cookies = Cookies.get()
    for (const cookie in cookies) {
        setProfile(null)
        Cookies.remove(cookie, {path: '/'});
    }
  }

  const fetchAndSetProfile = () => {
    const profileData = Cookies.get('profile');
    if (profileData) {
      try {
        setProfile(profileData);
      } catch (error) {
        handleLogout();
      }
    }
  }

  const items = [
    { to: '/dashboard', label: 'Dashboard', icon: <LuLayoutDashboard /> },
    { 
      to: profile === 'Administrador' ? '/cabin' : '/cabin/callup/next', 
      label: 'Camarotes', 
      icon: <TbArmchair />,  
    },
    { to: '/events', label: 'Eventos', icon: <TbCalendarDue />, profile: 'Administrador' },
  ];

  const getSidebarItems = () => {
    return items.filter(item => 
      item?.profile == '' || item?.profile == null ||
      item?.profile === profile
    );
  };

  const Layout = ({ children }) => {
    
    if(profile == null)
      fetchAndSetProfile();

    const sidebarItems = getSidebarItems();

    return (
      <AuthProvider>
        <Row>
          <Col xs={1} className='col-center'>
            <Sidebar navItems={sidebarItems} bottomItems={[]} logout={{ to: '/', label: 'Sair', icon: <IoLogOutOutline /> }} handleLogout={handleLogout} />
          </Col>
          <Col xs={11}>
          <div className='main-wrapper'>
            {children}
          </div>
          </Col>
        </Row>
      </AuthProvider>
    );
  };

  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <div className="wrapp-that">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
          <Route path="/cabin" element={<Layout><Cabins /></Layout>} />
          <Route path="/cabin/callup/:eventId" element={<Layout><UsersCallup /></Layout>} />
          <Route path="/events" element={<Layout><Events /></Layout>} />
          <Route path="/dashboard/fanbase" element={<Layout><DashboardFanBase /></Layout>} />
          <Route path="/report/LastEntrances" element={<Layout><LastEntrancesReports /></Layout>} />
          <Route path="/organizations" element={<Layout><Organization /></Layout>} />
          <Route path="/monitoring" element={<Layout><Monitoring /></Layout>} />
          <Route path="/reporting/:eventId" element={<Layout><Reports /></Layout>} />
          <Route path="/visitors" element={<Layout><VisitorsList /></Layout>} />
          <Route path="/user/create" element={<Layout><CreateUser /></Layout>} />
          <Route path="/user/admin" element={<Layout><CreateAdminUser /></Layout>} />
          <Route path="/user/block" element={<Layout><BlockUserlist /></Layout>} />
          <Route path="/approvers" element={<Layout><Approvers /></Layout>} />
          <Route path="/user/edit/:id" element={<Layout><EditUser /></Layout>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App
