import React, { createContext } from 'react'
import PropTypes from 'prop-types';
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import isTokenInvalid from './hooks/jwtAuth';

import useAuth from './hooks/useAuth'

const Context = createContext({
    authenticated: false,
    loading: true,
    handleLogin: {},
    handleLogout: {}
})


AuthProvider.propTypes = {
    children: PropTypes.object
};

function AuthProvider<CompProps>({ children }) {

    const token = Cookies.get('token');
    if (token == "" || isTokenInvalid(token))
        return <Navigate to="/" />

    // TODO: Fazer uma busca no back para atualizar o token interno

    const { authenticated, loading, handleLogin, handleLogout } = useAuth()

    return (
        <Context.Provider value={{ authenticated, loading, handleLogin, handleLogout }}>
            {children}
        </Context.Provider >
    )
}

export { Context, AuthProvider }