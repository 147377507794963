import React, { useState, useEffect } from 'react';
import './CardLogin.sass';
import logo from '../../../images/logo_bepass_powered_by.svg';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface CardLoginProps {
  companyTitle: string;
  onSubmit: (data: { user: string; password: string }) => void;
  loginError: { status: boolean; message: string };
  onInputChange: (data: { user: string; password: string }) => void;  
  isSubmitDisabled: boolean;  
  loading: boolean; 
  onForgotPassword?: () => void;
}

const CardLogin: React.FC<CardLoginProps> = ({
  companyTitle,
  onSubmit,
  loginError,
  onInputChange,
  isSubmitDisabled,
  loading,
  onForgotPassword,
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    onInputChange({ user: username, password });
  }, [username, password, onInputChange]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isSubmitDisabled && !loading) { 
      onSubmit({ user: username, password });
    }
  };

  return (
    <div className='box-login'>
      {loading && <LoadingSpinner />} 
      <h2 className='login-title'>{companyTitle}</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-input-wrap'>
          <label htmlFor='username'>E-mail</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Digite seu E-mail"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className='form-input-wrap'>
          <label htmlFor='password'>Senha</label>
          <div className='password-input-container'>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              placeholder="Digite sua Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className='button-eye' type='button' onClick={togglePasswordVisibility}>
              {showPassword ? <VscEye /> : <VscEyeClosed />}
            </button>
          </div>
          <div className='forgot-password-container'>
            {loginError.status && <div className='error-message'>{loginError.message}</div>} 
            <div className='forgot-password'>
              <a
                onClick={onForgotPassword}
                className='forgot-password-link'
              >
                Esqueceu a senha?
              </a>
            </div>
          </div>
        </div>
        <button
          type='submit'
          className={`login-button ${!isSubmitDisabled ? 'enabled' : 'disabled'}`}
          disabled={isSubmitDisabled || loading} 
        >
          Entrar
        </button>
      </form>
      <img src={logo} alt='Logo' className='login-logo' />
    </div>
  );
};

export default CardLogin;
