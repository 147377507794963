import React from 'react';
import { NavLink } from 'react-router-dom';
import logo_bepass from '../../images/logo_bepass.png';
import './Sidebar.sass';

interface NavItem {
  to: string;
  label: string;
  icon: React.ReactElement;
}

export interface SidebarProps {
  navItems?: NavItem[];
  bottomItems?: NavItem[];
  logout?: NavItem;
  handleLogout?: () => void;
}

const renderNavItem = (item: NavItem): JSX.Element => (
  <NavLink
    key={item.to}
    to={item.to}
    style={{ textDecoration: 'none' }}
    className={({ isActive }) => (isActive ? 'active' : '')}
  >
    <li>
      <span className="sidebar-icon">{item.icon}</span>
      <span className='item-content'>{item.label}</span>
    </li>
  </NavLink>
);

const Sidebar: React.FC<SidebarProps> = ({ navItems = [], bottomItems = [], logout, handleLogout }) => {
  return (
    <div className="sidebar-wrapper">
      <div className='actions-container'>
        {navItems.map(renderNavItem)}
      </div>
      <div className='actions-container-bottom'>
        {bottomItems.map(renderNavItem)}
        <div onClick={handleLogout}>
          {logout && renderNavItem(logout)}
        </div>
        <img src={logo_bepass} alt="Bepass Logo" />
      </div>
    </div>
  );
};

export default Sidebar;