import React, { useState } from 'react';
import { BASE } from '../../basedata/constants';
import './Login.sass';
import Cookies from 'js-cookie';
import CardLogin from '../../dashboard-custom-components/components/Card/CardLogin/CardLogin';
import ProfileSelect from '../components/ProfileSelect';
import useAuth from '../../Context/hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import CardResetPassword from '../../dashboard-custom-components/components/Card/CardResetPassword/CardResetPassword';

function Login() {
  const [loginError, setLoginError] = useState({ status: false, message: '' });
  const { handleLogin } = useAuth()
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([
    {
      id: '',
      userId: '',
      firebaseId: '',
      role: '',
      companyId: '',
      status: ''
    }
  ]);
  const [showProfileSelection, setShowProfileSelection] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [supplierList, setSupplierList] = useState([
    {
      id: '',
      name: '',
      cabinId: ''
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };
  
  const handleBackToLogin = () => {
    setIsForgotPassword(false);
  };

  const onInputChange = (data: { user: string; password: string }) => {
    const trimmedUser = data.user.trim();
    const trimmedPassword = data.password.trim();
    setIsSubmitDisabled(trimmedUser === '' || trimmedPassword === '');
  };

  const onSubmit = (data: { user: string; password: string }) => {
    const trimmedUser = data.user.trim();
    const trimmedPassword = data.password.trim();

    if (trimmedUser === '' || trimmedPassword === '') {
      setLoginError({
        status: true,
        message: 'E-mail e senha não podem estar em branco.',
      });
      setTimeout(() => {
        setLoginError({ status: false, message: '' });
      }, 5000); 
      return;
    }

    setLoading(true);

    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.login}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: trimmedUser,
        password: trimmedPassword,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json(); 
        } else {
          return res.json().then((errorResponse) => {
            switch (res.status) {
              case 400:
                if (errorResponse.message && errorResponse.message.includes('login must be an email')) {
                  setLoginError({
                    status: true,
                    message: 'Por favor informe um e-mail válido.',
                  });
                } 
                break;
              case 401:
                setLoginError({
                  status: true,
                  message: 'E-mail ou senha inválidos.',
                });
                break;
              case 429:
                setLoginError({
                  status: true,
                  message: 'Você excedeu o número de tentativas de login. Por favor, tente novamente mais tarde.',
                });
                break;
              default:
                setLoginError({
                  status: true,
                  message: 'Houve um problema, tente novamente.',
                });
            }
            setLoading(false);
            setTimeout(() => {
              setLoginError({ status: false, message: '' });
            }, 5000);
            return null;
          });
        }
      })
      .then((response) => {
        if (response && response.token) {
          Cookies.set('name', response.name);
          Cookies.set('token', JSON.stringify(response.token));
          Cookies.set('auth', 'true');

          const { data } = response;
          if (data) {
            const { roles, suppliers } = data;
            setRoleList(roles);
            Cookies.set('roleList', JSON.stringify(roles));
            Cookies.set('token', response.token.api_key);

            const admin = roles.filter((role) => role.role === 'admin');
            let updatedSupplierList = suppliers.map(supplier => ({
              ...supplier,
              cabinId: supplier.cabinId || ''  
            }));

            if (admin.length !== 0) {
              updatedSupplierList = [
                ...updatedSupplierList,
                { name: 'Administrador', id: admin[0].companyId, cabinId: '' },  
              ];
            }
            setSupplierList(updatedSupplierList);
            Cookies.set('suppliersList', JSON.stringify(updatedSupplierList));

            if (roles.length > 1) {
              setShowProfileSelection(true);
            } else {
              const singleRole = roles[0];

              Cookies.set('id', singleRole.id);
              Cookies.set('userId', singleRole.userId);
              Cookies.set('firebaseId', singleRole.firebaseId);
              Cookies.set('company', singleRole.companyId);
              Cookies.set('role', JSON.stringify(singleRole));
              Cookies.set('status', singleRole.status);

              const profile = updatedSupplierList.find((supplier) => supplier.id === singleRole.companyId);
              if (profile) {
                Cookies.set('profile', profile.name);
                Cookies.set('cabinId', profile.cabinId); 
              } else {
                Cookies.set('cabinId', ''); 
              }

              handleLogin();
              navigate('/dashboard')
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoginError({
          status: true,
          message: 'Houve um problema, tente novamente.',
        });
        setLoading(false);
        setTimeout(() => {
          setLoginError({ status: false, message: '' });
        }, 5000); 
      });
  };

  const handleShowProfileSelection = () => {
    setShowProfileSelection(false);
  };

  const handleBackButton = () => {
    setIsForgotPassword(false);
  };

  return (
    <div className="login-wallpaper">
      <div className="box-card-login color-set">
        {isForgotPassword ? (
          <CardResetPassword
            handleBackButton={handleBackButton}
          />
        ) : showProfileSelection ? (
          <ProfileSelect
            suppliersList={supplierList}
            setShowProfileSelection={handleShowProfileSelection}
            roleList={roleList}
          />
        ) : (
          <CardLogin
            companyTitle={`Camarotes ${BASE.COMPANY_DATA.company_name}`}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            isSubmitDisabled={isSubmitDisabled}
            loginError={loginError}
            loading={loading}
            onForgotPassword={handleForgotPassword}
          />
        )}
      </div>
    </div>
  );
}

export default Login;
