import React, { useState } from 'react';
import { RiExpandUpDownLine } from 'react-icons/ri';
import './EventTableWithSelectButton.sass';
import ActiveStatus from '../../Status/ActiveStatus/ActiveStatus';

interface Event {
  id: string;
  name: string;
  description: string;
  code: string;
  entryDate: string;
  entryTime: string;
  status: boolean;
}

interface EventTableWithSelectButtonProps {
  events: Event[];
  onSelect: (id: string) => void;
  onSort: (column: string, direction: 'ASC' | 'DESC') => void;
}

const EventTableWithSelectButton: React.FC<EventTableWithSelectButtonProps> = ({ events, onSelect, onSort }) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

  const handleSort = (column: string) => {
    const direction = sortColumn === column && sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortColumn(column);
    setSortDirection(direction);
    onSort(column, direction);
  };

  return (
    <div className="container-table">
      <table>
        <thead>
          <tr>
            <th className="col">
              <div className="header-content" onClick={() => handleSort('eventName')}>
                <span className="text">Evento e Descrição</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
            <th className="col">
              <div className="header-content" onClick={() => handleSort('startDateTime')}>
                <span className="text">Data</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
            <th className="col">
              <div className="header-content" onClick={() => handleSort('isActive')}>
                <span className="text">Status</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id}>
              <td className="col col1">
                <input type="radio" name="selectEvent" onChange={() => onSelect(event.id)} />
                <div>
                  <div>{event.name} {event.description}</div>
                  <div>Código {event.code}</div>
                </div>
              </td>
              <td className="col col2">
                <div>{event.entryDate}</div>
                <div>{event.entryTime}</div>
              </td>
              <td className="col col3">
                <ActiveStatus status={event.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTableWithSelectButton;


