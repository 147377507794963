import React, { useState } from 'react'
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder'
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal'
import './modalEventDelete.sass'
import { BASE } from '../../basedata/constants'
import { EventData } from '../Events/Intefaces/InterfaceEventData'
import DeleteDataButton from '../../dashboard-custom-components/components/Button/DeleteDataButton/DeleteDataButton'
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner'

interface ModalEventDeleteProps {
    show: boolean
    onHide: () => void
    event: EventData | null
}

const ModalEventDelete: React.FC<ModalEventDeleteProps> = ({ show, onHide, event }) => {   
    const [loading, setLoading] = useState(false);

    const handleDeleteDataClick = () => {
        if (event == null) return;
        setLoading(true);  
        console.log(event.id)
        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${event.id}`, {
            method: 'DELETE',
        })
        .then((resg) => resg.json())
        .then((response) => {
            console.log("Event deleted successfully:", response);
            setLoading(false); 
            onHide(); 
            window.location.reload(); 
        })
        .catch((error) => {
            console.error("Error deleting event:", error);
            setLoading(false); 
        });
    }

    return (
        <LargeModal show={show} onHide={onHide} title="Excluir Evento">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className='container-modal-delete-event'>
                <p> Você deseja excluir o evento <span> {event ? `${event.code} - ${event.title}` : "Evento não encontrado"} </span> ? </p>
            </div>

            <div className='container-delete-button'>

                <div className='container-modal-delete-event-button'>
                    <DeleteDataButton handleOnClick={handleDeleteDataClick} label="EXCLUIR" />
                </div>
            </div>
        </LargeModal>
    )
}

export default ModalEventDelete

