import React, { useState } from 'react';
import './CardResetPassword.sass';
import { SlArrowLeft } from 'react-icons/sl';
import logo from '../../../images/logo_bepass_powered_by.svg';
import { BASE } from '../../../../basedata/constants';
import { BsCheckCircle } from 'react-icons/bs';

interface CardResetPasswordProps {
  handleBackButton: () => void;
}

const CardResetPassword: React.FC<CardResetPasswordProps> = ({
  handleBackButton,
}) => {
  const [email, setEmail] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (e.target.validity.valid) {
      setIsSubmitDisabled(false);
      setErrorMessage('');
    } else {
      setIsSubmitDisabled(true);
      setErrorMessage('Por favor, insira um endereço de e-mail válido.');
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch(`${BASE.COMPANY.url}${BASE.COMPANY.reset_password}`, {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((resg) => resg.json())
      .then((res) => {
        console.log(res)
        if (res.status === 'success') {
          setSuccessMessage(
            'Um e-mail foi enviado com instruções para redefinir sua senha.'
          );
          setIsFormVisible(false);
        } else {
          setErrorMessage(
            res.message
          );
        }
      })
      .catch(() => {
        setErrorMessage('Erro de conexão. Por favor, tente novamente.');
      });
  };

  return (
    <div className="reset-password-card">
      <h2>Camarotes {BASE.COMPANY_DATA.company_name}</h2>

      {isFormVisible ? (
        <>
          <div className="card-back-button full-width">
            <a onClick={handleBackButton}>
              <SlArrowLeft />
              <span  className="link-back">Voltar</span>
            </a>
          </div>
          <p>
            Se o e-mail fornecido constar em nosso sistema, você receberá um link para redefinição de senha em até 5 minutos.
          </p>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                placeholder="exemplo@gmail.com"
                value={email}
                onChange={handleEmailChange}
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
            <button
              type="submit"
              className={`submit-button ${
                !isSubmitDisabled ? 'enabled' : 'disabled'
              }`}
            >
              Próximo
            </button>
          </form>
        </>
        ) : (
        <div className="success-message-container">
          <BsCheckCircle className="success-icon" />
          <p>Sua solicitação de redefinição de senha foi enviada com sucesso.</p>
          <button className="success-button" onClick={handleBackButton}>Login</button>
        </div>
      )}

      <footer>
        <img src={logo} alt="Logo" className="login-logo" />
      </footer>
    </div>
  );
};

export default CardResetPassword;
