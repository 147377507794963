import React from 'react';
import './CardNumbersLarge.sass';
import { Col, Row } from 'react-bootstrap';
import ProgressBar from '../../ProgressBar/ProgressBar';

interface CardNumbersLargeProps {
    label: string;
    total: string;
    percent: number;
}
const CardNumbersLarge: React.FC<CardNumbersLargeProps> = ({ 
label, total, percent
}) => {
  return (
    <Row className="card-large-numbers">
      <Col xs={3}>
        <h3>{label}</h3>
        <p className='total-value'>{percent}%</p>
      </Col>
      <Col xs={9}>
        <h3>{total}</h3>
        <ProgressBar percent={percent}/>
      </Col>
    </Row>
  );
};

export default CardNumbersLarge;
