import React, { useEffect, useState } from "react";
import "./CardEventActive.sass";
import EventStatus from "../../Status/EventStatus/EventStatus";

interface CardEventProps {
  teamVisiting?: string;
  teamHome?: string;
  eventDescription?: string;
  eventDate: string;
  eventDay: string;
  eventTime: string;
  isActive: boolean;
  eventType: string;
  eventName?: string;
}

const CardEventActive: React.FC<CardEventProps> = ({
  teamVisiting,
  teamHome,
  eventDescription,
  ...props
}) => {
  const [isEvent, setIsEvent] = useState(false);

  useEffect(() => {
    if (props.eventType === "event") {
      setIsEvent(true);
    }
  }, []);

  return (
    <div className="container-card">
      <div className="content-wraper">
        <div className="content-box content-box-event-date">
            <h1 className="mb-16">{props.eventDate}</h1>
            <h2>{props.eventDay}</h2>
            <h2>{props.eventTime}</h2>
        </div>
        <div className="content-box content-box-event-detail">
          <EventStatus isActive={props.isActive} />
          {isEvent ? (
            <div className="content vertical-alignment">
              <h1>{props.eventName}</h1>
              <h2 className="font-weight-300">{eventDescription}</h2>
            </div>
          ) : (
            <div className="content vertical-alignment">
              <h1>{teamHome}</h1>
              <h2 className="font-weight-300">x</h2>
              <h1 className="mb-16">{teamVisiting}</h1>
              <h2 className="font-weight-300">{eventDescription}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardEventActive;
