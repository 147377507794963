import React from 'react';
import './CardNumbersSmall.sass';

interface CardNumbersSmallProps {
    label: string;
    total: string;
    icon: React.ReactElement;
}
const CardNumbersSmall: React.FC<CardNumbersSmallProps> = ({ 
label, total, icon
}) => {
  return (
    <div className="card-small-numbers">
      <h3>{label}</h3>
      <div className="card-small-description">
        <p className='total-value'>{total}</p>

        <i className="small-icon">
          {icon}
        </i>
      </div>
    </div>
  );
};

export default CardNumbersSmall;
