import React, { useState, useEffect } from "react";
import "./AutoUpdateButton.sass";

interface AutoUpdateButtonProps {
  onToggle: (isEnabled: boolean) => void;
  isEnabled: boolean; 
}

const AutoUpdateButton: React.FC<AutoUpdateButtonProps> = ({ onToggle, isEnabled }) => {
  const [isAutoUpdateEnabled, setIsAutoUpdateEnabled] = useState(isEnabled); 

  useEffect(() => {
    setIsAutoUpdateEnabled(isEnabled); 
  }, [isEnabled]);

  const handleToggle = () => {
    setIsAutoUpdateEnabled(!isAutoUpdateEnabled);
    onToggle(!isAutoUpdateEnabled);
  };

  return (
    <div className="auto-update-button">
      <h1>Atualização Automática</h1>
      <label className="toggle-switch">
        <input type="checkbox" checked={isAutoUpdateEnabled} onChange={handleToggle} />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default AutoUpdateButton;