import React, { useState, useEffect } from 'react';
import { RiExpandUpDownLine } from "react-icons/ri";
import './TableEventsList.sass';
import ActiveStatus from '../../Status/ActiveStatus/ActiveStatus';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from 'react-icons/fa6';
import { EventData } from '../../../../views/Events/Intefaces/InterfaceEventData';
import ModalEventEdit from '../../../../views/components/modalEventEdit';
import ModalEventDelete from '../../../../views/components/modalEventDelete';
import ModalEventActive from '../../../../views/components/modalEventActive';
import ModalEventDisable from '../../../../views/components/modalEventDisable';
import { format } from 'date-fns';

const TableEventsList: React.FC<{ data: EventData[] }> = ({ data }) => {

  const [showEditModal, setShowEditModal] = useState(false) 
  const [showActiveModal, setShowActiveModal] = useState(false) 
  const [showDeleteModal, setShowDeleteModal] = useState(false) 
  const [showDisableModal, setShowDisableModal] = useState(false) // Novo estado para o ModalEventDisable
  const [selectedEvent, setSelectedEvent] = useState<EventData | null>(null);

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedData, setSortedData] = useState<EventData[]>([]);

  const sortData = (data: EventData[], column: string | null, direction: 'asc' | 'desc'): EventData[] => {
    if (!column) return data;

    return [...data].sort((a, b) => {
      let aValue = a[column as keyof EventData];
      let bValue = b[column as keyof EventData];

      if (typeof aValue === 'string') 
        aValue = aValue.toLowerCase();
      
      if (typeof bValue === 'string') 
        bValue = bValue.toLowerCase();
      
      if (aValue === null || aValue === undefined) return direction === 'asc' ? 1 : -1;
      if (bValue === null || bValue === undefined) return direction === 'asc' ? -1 : 1;
  
      if (aValue === bValue) return 0;
  
      return direction === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });
  };
  

  useEffect(() => {
    setSortedData(sortData(data, sortColumn, sortDirection));
  }, [data, sortColumn, sortDirection]);

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleEditClick = (event: EventData) => {
    setSelectedEvent(event);
    setShowEditModal(true);
  };

  const handleActivateClick = (event: EventData) => {
    setSelectedEvent(event);
    setShowActiveModal(true);
  };

  const handleDeactivateClick = (event: EventData) => {
    setSelectedEvent(event);
    setShowDisableModal(true);
  };

  const handleDeleteClick = (event: EventData) => {
    setSelectedEvent(event);
    setShowDeleteModal(true);
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="col col1" onClick={() => handleSort('title')}>
              <div className="header-content">
                <span className="text">Evento e Descrição</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
            <th className="col col2" onClick={() => handleSort('startDate')}>
              <div className="header-content">
                <span className="text">Data</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
            <th className="col col3" onClick={() => handleSort('active')}>
              <div className="header-content">
                <span className="text">Status</span>
                <RiExpandUpDownLine className="icon" />
              </div>
            </th>
            <th className="col col4">
              <div className="header-content">
                <span className="text">Ações</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((event, index) => (
            <tr key={index}>
              <td className="col col1">
                <div>{event.title}</div>
                <div>Código: {event.code}</div>
              </td>
              <td className="col col2">
                {format(event.startDate, 'dd/MM/yyyy - HH:mm')}
              </td>
              <td className="col col3">
                <ActiveStatus status={event.active} />
              </td>
              <td className="col col4">
                <div className="custom-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Menu
                      <i className='dropdown-icon'><FaChevronDown /></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleEditClick(event)}>Editar</Dropdown.Item>
                      {event.active ? (
                        <Dropdown.Item onClick={() => handleDeactivateClick(event)}>Inativar</Dropdown.Item>
                      ) : (
                        <Dropdown.Item onClick={() => handleActivateClick(event)}>Ativar</Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={() => handleDeleteClick(event)}>Excluir</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalEventEdit
        show={showEditModal}
        onHide={() => { setShowEditModal(false); setSelectedEvent(null); }} 
        event={selectedEvent}
      />

      <ModalEventDelete
        show={showDeleteModal}
        onHide={() => { setShowDeleteModal(false); setSelectedEvent(null); }} 
        event={selectedEvent}
      />

      {selectedEvent && showActiveModal && (
        <ModalEventActive
          show={showActiveModal}
          onHide={() => { setShowActiveModal(false); setSelectedEvent(null); }}
          event={selectedEvent}
        />
      )}

      {selectedEvent && showDisableModal && (
        <ModalEventDisable
          show={showDisableModal}
          onHide={() => { setShowDisableModal(false); setSelectedEvent(null); }}
          event={selectedEvent}
        />
      )}
    </div>
  );
}

export default TableEventsList;



