import React, { useState } from "react";
import "./CardProfileSelection.sass";
import logo_bepass from "../../../images/logo_bepass_powered_by.svg";
import { SlArrowLeft } from "react-icons/sl";
import { MdKeyboardArrowDown } from "react-icons/md";
import DropdownSelect from "../../DropdownSelect/DropdownSelect";

interface Supplier {
  id: string;
  name: string;
  cabinId: string;
}

interface CardProfileSelectionProps {
  supplierList: Supplier[];
  companyName: string;
  companyId: string;
  disableButton: boolean;
  handleSelect: (selectedId: string) => void;
  handleBackButton: () => void;
  handleSubmitButton: (event: React.FormEvent) => void;
}

const CardProfileSelection: React.FC<CardProfileSelectionProps> = ({
  supplierList,
  companyName,
  companyId,
  disableButton,
  handleSelect,
  handleBackButton,
  handleSubmitButton,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="card-container">
      <form className="card-content-wrapper" onSubmit={handleSubmitButton}>
        <h2>Camarotes {companyName}</h2>
        <div className="card-back-button full-width">
          <a onClick={handleBackButton}>
            <SlArrowLeft />
            <span>Voltar</span>
          </a>
        </div>
        <div className="card-selection-section full-width">
          <label htmlFor="cabin-select">Selecione o camarote ou perfil</label>
          <DropdownSelect
            items={supplierList}
            selectedId={companyId}
            onSelect={handleSelect}
            labelKey="name"
            subLabelKey="cabinId"
          />
        </div>
        <div className="card-login-section full-width">
          <button
            className={`${disableButton ? "button-disabled" : "button-enable"} full-width`}
            disabled={disableButton}
            type="submit"
          >
            Entrar
          </button>
        </div>
        <div className="card-footer">
          <img src={logo_bepass} alt="Logo da bepass" />
        </div>
      </form>
    </div>
  );
};

export default CardProfileSelection;



