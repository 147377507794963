import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DefaultModal from "../../dashboard-custom-components/components/Modal/DefaultModal/DefaultModal";
import DefaultPagination from "../../dashboard-custom-components/components/Pagination/DefaultPagination";
import SearchByDate from "../../dashboard-custom-components/components/SearchField/SearchByDate";
import DefaultButton from "../../dashboard-custom-components/components/Button/DefaultButton/DefaultButton";
import SearchWithoutIcon from "../../dashboard-custom-components/components/SearchField/SearchWithoutIcon";
import EventTableWithSelectButton from "../../dashboard-custom-components/components/Table/EventTableWithSelectButton/EventTableWithSelectButton";
import ButtonWithoutBorder from "../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder";
import ConditionalButton from "../../dashboard-custom-components/components/Button/ConditionalButton/ConditionalButton";
import "./modalSelectEvent.sass";
import { BASE } from "../../basedata/constants";
import ModalNoData from "../../dashboard-custom-components/components/Modal/ModalNoData/ModalNoData";
import DeleteDataButton from "../../dashboard-custom-components/components/Button/DeleteDataButton/DeleteDataButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import iconNoData from "../../../src/images/icon_no_data.svg";

interface User {
    id: string;
    document: string;
    status: boolean;
}

const ModalShowWaitingList: React.FC<{ show: boolean; onHide: () => void; eventId:string;groupId:string }> = ({ show, onHide, eventId, groupId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [showNoDataModal, setShowNoDataModal] = useState(false);

  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setSearchTerm('');
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR').format(date);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const transformEventData = (event: any) => ({
    id: event.id,
    document: event.document,
  });

  const getAllEvents = (page = 1, limit = 5, searchName = '', startDateTime = '', endDateTime = '', orderByColumn = '', orderByDirection = '') => {
    const url = new URL(`${BASE.USER.url}${BASE.USER.waiting_list}/${eventId}/${groupId}`);
    url.searchParams.append('page', page.toString());
    url.searchParams.append('limit', limit.toString());
    if (searchName) url.searchParams.append('searchName', searchName);
    if (startDateTime) url.searchParams.append('startDateTime', startDateTime);
    if (endDateTime) url.searchParams.append('endDateTime', endDateTime);
    if (orderByColumn) url.searchParams.append('orderByColumn', orderByColumn);
    if (orderByDirection) url.searchParams.append('orderByDirection', orderByDirection);

    fetch(url.toString(), {
      method: 'GET',
    })
    .then((res) => res.json())
    .then((response) => {
      const transformedEvents = response[0]?.map(transformEventData);
      setUsers(transformedEvents);
      setTotalPages(Math.ceil(response[1] / limit));
      setTotalRecords(response[1]);
      setShowNoDataModal(false);
    })
    .catch((error) => {
      console.log(error);
      setShowNoDataModal(true);
    });
  };

  useEffect(() => {
    if (show) {
      getAllEvents(currentPage);
    }
  }, [currentPage, show]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    getAllEvents(newPage, 5, searchTerm, startDate, endDate);
  };

  const handleSearch = () => {
    setCurrentPage(1);  
    getAllEvents(1, 5, searchTerm, startDate, endDate);
  };

  const handleSort = (column: string, direction: 'ASC' | 'DESC') => {
    getAllEvents(1, 5, searchTerm, startDate, endDate, column, direction);
  };

  const handleSelectEvent = (id: string) => {
    setSelectedEventId(id);
  };

  if (!show) {
    return null;
  }

  const handleDeleteDataClick = (data) => {

    
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
  
    fetch(`${BASE.USER.url}${BASE.USER.waiting_list}/${eventId}/${groupId}/${data}`, requestOptions)
    .then(response => response.json())
    .then(data => {
      getAllEvents()
    });
    

  }
  
  return (
    <div>
      <DefaultModal
        show={show}
        onHide={onHide}
        title="Convidados aguardando cadastro"
      >
        <div className="modal-select-event-container">


        {users.length > 0 ? (
          <div>
              <h4>Instruções</h4>
              <p>
                Para ativar o cadastro pendente, o seu convidado precisa acessar:{" "}
                <a
                  href={BASE.onboarding.base_url}
                  target="_blank"
                  rel="noreferrer"
                  >
                  {BASE.onboarding.base_url}
                </a>{" "}
                e terminar o cadastro com o registro facial.
              </p>
          </div>
        ) : ''}
      
              <div className="container-table">
                  {users.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="col col7">
                          <span className="header-content">Documento do Convidado</span>
                        </th>
                        <th className="col">
                          <span className="header-content">Ação</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => (
                        <tr key={user.document}>
                          <td className="col col7">
                            <div>{user.document}</div>
                          </td>
                          <td className="col">
                            <button
                              onClick={() => handleDeleteDataClick(user.id)}
                              className="single-col container-delete-data-button"
                            >
                              Excluir
                              <span>
                                <RiDeleteBin6Line />
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
      
                  ) : (
                      <div className="no-users-message">
                        Não há convidados aguardando cadastro no momento.
                      </div>
                  )}

          </div>

        </div>
      </DefaultModal>
  
      <ModalNoData
        show={showNoDataModal}
        onHide={() => {
          setShowNoDataModal(false);
          resetFilters();
        }}
        title="Nenhum Evento Encontrado"
      >
        <p>Não há convidados aguardando cadastro no momento.</p>
      </ModalNoData>
    </div>
  );
  
};

export default ModalShowWaitingList;



