import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./DeleteDataButton.sass";

interface DeleteDataButtonProps {
  handleOnClick: () => void;
  label: string;
}

const DeleteDataButton: React.FC<DeleteDataButtonProps> = ({
  handleOnClick,
  label,
}) => {
  return (
    <button onClick={handleOnClick} className="container-delete-data-button">
      {label}
      <span>
        <RiDeleteBin6Line />
      </span>
    </button>
  );
};

export default DeleteDataButton;