import React, { useState } from 'react';
import './ProfileSelect.sass';
import CardProfileSelection from '../../dashboard-custom-components/components/Card/CardProfileSelection/CardProfileSelection';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASE } from '../../basedata/constants';

interface Supplier {
  id: string;
  name: string;
  cabinId: string;
}

interface Role {
  id: string;
  userId: string;
  firebaseId: string;
  role: string;
  companyId: string;
  status: string;
}

interface ProfileSelectProps {
  suppliersList: Supplier[];
  roleList: Role[];
  setShowProfileSelection: () => void;
}

const ProfileSelect: React.FC<ProfileSelectProps> = ({
  suppliersList,
  setShowProfileSelection,
  roleList
}) => {
  const navigate = useNavigate();
  const [company, setCompany] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const handleSelect = (selectedId: string) => {
    if (selectedId) {
      setDisableButton(false);
      setCompany(selectedId);
    } else {
      setDisableButton(true);
      setCompany('');
    }
  };

  const handleBackButton = () => {
    setShowProfileSelection();
  };

  const handleSubmitButton = (event: React.FormEvent) => {
    event.preventDefault();
    const role = roleList.find((role) => role.companyId === company);

    if (role) {
      Cookies.set('id', role.id);
      Cookies.set('userId', role.userId);
      Cookies.set('firebaseId', role.firebaseId);
      Cookies.set('company', role.companyId);
      Cookies.set('role', JSON.stringify(role));
      Cookies.set('status', role.status);
      const profile = suppliersList.find((supplier) => supplier.id === company);
      if (profile) {
        Cookies.set('profile', profile.name);
        Cookies.set('cabinId', profile.cabinId); 
      }

      navigate('/dashboard');
    }
  };

  return (
    <CardProfileSelection
      companyName={BASE.COMPANY_DATA.company_name}
      supplierList={suppliersList}
      handleSelect={handleSelect}
      companyId={company}
      disableButton={disableButton}
      handleSubmitButton={handleSubmitButton}
      handleBackButton={handleBackButton}
    />
  );
};

export default ProfileSelect;
