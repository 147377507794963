import React, { useState } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import ActiveDataButton from '../../dashboard-custom-components/components/Button/ActiveDataButton/ActiveDataButton';
import './modalActiveCabin.sass';
import { BASE } from '../../basedata/constants';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';

interface ModalActiveCabinProps {
    show: boolean;
    onHide: () => void;
    cabinNumber: string;
    cabinName: string;
    supplierId: string;
}

const ModalActiveCabin: React.FC<ModalActiveCabinProps> = ({ show, onHide, cabinNumber, cabinName, supplierId }) => {
    
    const [loading, setLoading] = useState(false);

    const handleActiveDataClick = () => {
        setLoading(true);  
        fetch(`${BASE.USER.url}${BASE.USER.supplier_status}/active?id=${supplierId}`, {
            method: 'PUT',
        })
        .then((resg) => resg.json())
        .then((response) => {
            console.log("Supplier activated successfully:", response);
            setLoading(false); 
            onHide(); 
            window.location.reload();
        })
        .catch((error) => {
            console.error("Error activating supplier:", error);
            setLoading(false);  
        });
    };
    
    return (
        <LargeModal show={show} onHide={onHide} title="Ativar Camarote">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <>
                <div className='container-modal-active-cabin'>
                    <p> Você deseja ativar o camarote <span> {cabinNumber} - {cabinName} </span> ? </p>
                </div>

                <div className='container-active-button'>
                    <div className='container-modal-active-data-button'>
                        <ActiveDataButton handleOnClick={handleActiveDataClick} label="ATIVAR" />
                    </div>
                </div>
            </>
        </LargeModal>
    );
}

export default ModalActiveCabin;







