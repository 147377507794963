import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DefaultModal from "../../dashboard-custom-components/components/Modal/DefaultModal/DefaultModal";
import DefaultPagination from "../../dashboard-custom-components/components/Pagination/DefaultPagination";
import SearchByDate from "../../dashboard-custom-components/components/SearchField/SearchByDate";
import DefaultButton from "../../dashboard-custom-components/components/Button/DefaultButton/DefaultButton";
import SearchWithoutIcon from "../../dashboard-custom-components/components/SearchField/SearchWithoutIcon";
import EventTableWithSelectButton from "../../dashboard-custom-components/components/Table/EventTableWithSelectButton/EventTableWithSelectButton";
import ConditionalButton from "../../dashboard-custom-components/components/Button/ConditionalButton/ConditionalButton";
import "./modalSelectEvent.sass";
import { BASE } from "../../basedata/constants";
import ModalNoData from "../../dashboard-custom-components/components/Modal/ModalNoData/ModalNoData";

interface Event {
    id: string;
    name: string;
    description: string;
    code: string;
    entryDate: string;
    entryTime: string;
    status: boolean;
}

const ModalSelectEvent: React.FC<{ show: boolean; onHide: () => void; setEventId: Dispatch<SetStateAction<string | null>> }> = ({ show, onHide, setEventId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const [orderByColumn, setOrderByColumn] = useState<string>('');
  const [orderByDirection, setOrderByDirection] = useState<'ASC' | 'DESC'>('ASC');

  const resetFilters = () => {
    setCurrentPage(1);
    setStartDate('');
    setEndDate('');
    setSearchTerm('');
    setTotalPages(1);
    setTotalRecords(0);
    setEvents([]);
    setSelectedEventId(null);
    setShowNoDataModal(false);
  };  
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR').format(date);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const transformEventData = (event: any) => ({
    id: event.id,
    name: event.eventName,
    description: event.description || '',
    code: event.eventCode,
    entryDate: formatDate(event.startDateTime),
    entryTime: formatTime(event.startDateTime),
    status: event.isActive,
  });

  const getAllEvents = (page = 1, limit = 5, searchName = '', startDateTime = '', endDateTime = '', orderByColumn = '', orderByDirection = '') => {
    const url = new URL(`${BASE.EVENT.url}${BASE.EVENT.all_events}`);
    url.searchParams.append('page', page.toString());
    url.searchParams.append('limit', limit.toString());
    if (searchName) url.searchParams.append('searchName', searchName);
    if (startDateTime) url.searchParams.append('startDateTime', startDateTime);
    if (endDateTime) url.searchParams.append('endDateTime', endDateTime);
    if (orderByColumn) url.searchParams.append('orderByColumn', orderByColumn);
    if (orderByDirection) url.searchParams.append('orderByDirection', orderByDirection);

    fetch(url.toString(), {
      method: 'GET',
    })
    .then((res) => res.json())
    .then((response) => {
      if (response.events && response.events.length > 0) {
        const transformedEvents = response.events.map(transformEventData);
        setEvents(transformedEvents);
        setTotalPages(Math.ceil(response.total / limit));
        setTotalRecords(response.total);
        setShowNoDataModal(false);
      } else {
        setShowNoDataModal(true);
      }
    })
    .catch((error) => {
      console.log(error);
      setShowNoDataModal(true);
    });
  };

  useEffect(() => {
    if (show) {
      getAllEvents(currentPage, 5, searchTerm, startDate, endDate, orderByColumn, orderByDirection);
    } else {
      resetFilters();
    }
  }, [show]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    getAllEvents(newPage, 5, searchTerm, startDate, endDate, orderByColumn, orderByDirection);
  };

  const handleSearch = () => {
    setCurrentPage(1);  
    getAllEvents(currentPage, 5, searchTerm, startDate, endDate, orderByColumn, orderByDirection);
  };

  const handleSort = (column: string, direction: 'ASC' | 'DESC') => {
    setOrderByColumn(column);
    setOrderByDirection(direction);
    getAllEvents(currentPage, 5, searchTerm, startDate, endDate, column, direction);
  };

  const handleSelectEvent = (id: string) => {
    setSelectedEventId(id);
  };

  if (!show) {
    return null;
  }

  const handleButtonClick = () => {
    if (selectedEventId) {
      setEventId(selectedEventId)
      onHide()
    }
  };

  return (
    <div>
      <DefaultModal
        show={show}
        onHide={onHide}
        title="Pesquisa e Seleção de Eventos"
      >
        <div className="modal-select-event-container">
          <div className="container-search">
            <div className="box-search-by-date">
                <h2>Período</h2>
                <SearchByDate
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDateProp={startDate}
                    endDateProp={endDate}
                />
            </div>

            <div className="box-default-search">
                <h2>Nome ou Código do Evento</h2>
                <SearchWithoutIcon placeHolder="Nome ou Código do Evento" setSearchTerm={setSearchTerm} />
            </div>

            <div className="box-button">
                <DefaultButton label="Pesquisar" handleOnClick={handleSearch} />
            </div>
          </div>

          <div className="container-table">
                <EventTableWithSelectButton events={events} onSelect={handleSelectEvent} onSort={handleSort} />
          </div>

          <div className="container-page">
            <DefaultPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalRecords={totalRecords}
            />
          </div>

          <div className="container-button">
            <div className="container-default-button">
              <ConditionalButton
                handleOnClick={handleButtonClick}
                label="Visualizar"
                disabled={!selectedEventId}
              />
            </div>
          </div>
        </div>
      </DefaultModal>

      <ModalNoData 
        show={showNoDataModal} 
        onHide={() => {
          setShowNoDataModal(false);
        }} 
        title="Nenhum Evento Encontrado"
      >
        <p>Não foram encontrados eventos com os critérios fornecidos.</p>
      </ModalNoData>
    </div>
  );
};

export default ModalSelectEvent;
